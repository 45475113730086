import { useEffect, useState } from "react";
import { getUrlParams } from "../../../services/helpers";
import Pagination from "../../../components/general/Pagination";
import { broadcrumb } from "../../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import Loading from "../../../components/general/Loading";
import { useFetchTransactionsQuery } from "../../../store/account/main/accountStore";
import { useCalculateHeight } from "../../../hooks/calculateHeight";

const pages = [{ name: "Historiku i krediteve", href: "#", current: true }];

export default function Transactions() {
  const params = getUrlParams();
  const dispatch = useDispatch();
  const height = useCalculateHeight(150);

  //#region rtk
  const { data } = useFetchTransactionsQuery({
    module: window.location.href,
    ...params,
  });
  //#endregion

  //#region states
  const [page, setPage] = useState(params);
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(page);
  };
  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);
  //#endregion

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <div className="overflow-auto" style={{ height: height }}>
        <table className="min-w-full divide-y divide-neutral-300">
          <thead className="bg-neutral-300 sticky top-0 z-10">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Moduli i perdorur
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Numri i krediteve
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Data
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-neutral-300 bg-white">
            {data?.data.map((transaction: any) => (
              <tr key={transaction.id} className="even:bg-gray-100">
                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                  <div className="text-gray-900">
                    {transaction.module.display_name}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                  <div className="text-gray-900">
                    <b>{transaction.price}</b> Kredite
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                  <div className="text-gray-900">{transaction.created_at}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={data?.current_page ?? 1}
        from={data?.from ?? 0}
        to={data?.to ?? 0}
        total={data?.total ?? 0}
        last={data?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  );
}

import { CameraIcon, HeartIcon, ShareIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import {
  useFetchPropertiesQuery,
  useFetchPropertyQuery,
  useSavePropertyMutation,
} from "../../../store/properties/PropertiesStore";
import PropertiesCarousel from "../../../components/front/carousel/PropertiesCarousel";
import { useParams } from "react-router-dom";
import {
  copyUrl,
  findInObjects,
  isLogedIn,
  isNotNullOrUndefined,
  isNumeric,
  mapAddress,
  price,
  removeCookie,
} from "../../../services/helpers";
import NotFound from "../../NotFound";
import Loading from "../../../components/general/Loading";
import {
  Cities,
  PropertyRooms,
  PropertyFurnitures,
  PropertyDocuments,
  CreatePropertyStatuses,
} from "../../../services/constants";
import PropertyModal from "./components/PropertyModal";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/main/MainSlice";
import PropertyContactModal from "../../../components/general/contacts/PropertyContactModal";
import ContactSeller from "../../../components/general/contacts/ContactSeller";
// import { Helmet } from "react-helmet";

export default function PropertyDetails() {
  //#region constants
  const dispatch = useDispatch();
  const { id } = useParams();
  //#endregion

  //#region rtk
  const { data: { data: properties } = {} } = useFetchPropertiesQuery({});
  const { data: { data: propertyData } = {}, isFetching: isFetchingProperty } =
    useFetchPropertyQuery({ id: id });
  const [saveProperty] = useSavePropertyMutation();
  let images = propertyData?.images_urls;
  //#endregion

  //#region state
  const [open, setOpen] = useState<boolean>(false);
  //#endregion

  //#region refs
  const refContactModal = useRef<any>(null);
  //#endregion

  //#region functions
  if (!isNumeric(id)) {
    return <NotFound />;
  }
  const handleSaveProperty = async () => {
    try {
      if (isLogedIn()) {
        const result: any = await saveProperty({ ids: [propertyData.id] });
        if (result?.data?.status) {
          dispatch(
            showNotification({ msg: result.data.message, visible: true })
          );
        }
        if (result?.error?.status === 401) {
          dispatch(
            showNotification({ msg: "Please login first!", visible: true })
          );
          removeCookie("authToken");
          window.location.reload();
        }
      } else {
        dispatch(
          showNotification({ msg: "Please login first!", visible: true })
        );
      }
    } catch (error) {}
  };

  const handleCopyUrl = () => {
    copyUrl();
    dispatch(
      showNotification({ msg: "Linku u kopjua me sukses!", visible: true })
    );
  };
  //#endregion

  if (isFetchingProperty) {
    return <Loading />;
  } else {
    if (!propertyData?.id) {
      return <NotFound />;
    }
  }

  return (
    <>
      {/* <Helmet>
        <title>{propertyData?.name} - My Blog</title>
        <meta name="description" content={propertyData?.desc} />
        <meta property="og:title" content={propertyData?.name} />
        <meta property="og:description" content={propertyData?.desc} />
        <meta property="og:image" content={images[0]?.url} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={propertyData?.name} />
        <meta name="twitter:description" content={propertyData?.desc} />
        <meta name="twitter:image" content={images[0]?.url} />
      </Helmet> */}
      <PropertyContactModal
        propertyName={propertyData?.name}
        forwardedRef={refContactModal}
      />
      <div className={`mx-auto max-w-screen-2xl`}>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-2 relative">
          {images.length === 1 && (
            <div className={`mt-1 col-span-12`}>
              <div className="col-span-8 col-span-6 col-span-4"></div>
              <img
                onClick={() => setOpen(true)}
                className="aspect-[16/10] w-full object-cover"
                src={images[0]?.url}
                alt=""
              />
            </div>
          )}
          {images.length === 2 && (
            <>
              <div className={`mt-1 col-span-6`}>
                <div className="col-span-8 col-span-6 col-span-4"></div>
                <img
                  onClick={() => setOpen(true)}
                  className="aspect-[16/10] w-full object-cover"
                  src={images[0]?.url}
                  alt=""
                />
              </div>
              <div className={`mt-1 col-span-6`}>
                <div className="col-span-8 col-span-6 col-span-4"></div>
                <img
                  onClick={() => setOpen(true)}
                  className="aspect-[16/10] w-full object-cover"
                  src={images[1]?.url}
                  alt=""
                />
              </div>
            </>
          )}
          {images.length > 2 && (
            <>
              <div className={`mt-1 col-span-${images?.length < 5 ? 8 : 6}`}>
                <div className="col-span-8 col-span-6 col-span-4"></div>
                <img
                  onClick={() => setOpen(true)}
                  className="aspect-[16/10] w-full object-cover"
                  src={images[0]?.url}
                  alt=""
                />
              </div>
              <div
                className={`hidden md:block col-span-${
                  images?.length < 5 ? 4 : 6
                }`}
              >
                {images?.length > 2 && (
                  <div
                    className={`grid grid-cols-${
                      images?.length < 5 ? "1 gap-1" : "2 gap-2"
                    } my-1`}
                  >
                    <div>
                      <img
                        onClick={() => setOpen(true)}
                        className="aspect-[16/10] w-full object-cover"
                        src={images[1]?.url}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        onClick={() => setOpen(true)}
                        className="aspect-[16/10] w-full object-cover"
                        src={images[2]?.url}
                        alt=""
                      />
                    </div>
                  </div>
                )}
                {images?.length > 4 && (
                  <div className="grid grid-cols-2 gap-2 my-1">
                    <div>
                      <img
                        onClick={() => setOpen(true)}
                        className="aspect-[16/10] w-full object-cover"
                        src={images[3]?.url}
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        onClick={() => setOpen(true)}
                        className="aspect-[16/10] w-full object-cover"
                        src={images[4]?.url}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="absolute bottom-10 gap-2 mx-auto max-w-screen-2xl w-full">
            <div className="grid grid-cols-2">
              <div className="px-2">
                <button
                  onClick={handleSaveProperty}
                  type="button"
                  className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-neutral-800 hover:text-white"
                >
                  <HeartIcon
                    className={`-ml-0.5 h-5 w-5 ${
                      propertyData.liked ? "fill-black" : ""
                    }`}
                    aria-hidden="true"
                  />
                </button>
                <button
                  onClick={handleCopyUrl}
                  type="button"
                  className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-neutral-800 hover:text-white"
                >
                  <ShareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="text-right px-2">
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-black bg-opacity-75 px-2.5 py-1.5 text-sm font-semibold text-white dark:text-zinc-800 shadow-sm hover:bg-white hover:text-gray-800"
                >
                  <CameraIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  {images.length} Foto
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 relative dark:text-white mt-10 px-2 sm:px-0">
          <div className="col-span-12 md:col-span-7 lg:col-span-8">
            <h3 className="text-4xl ui-serif">{propertyData?.name}</h3>
            <h3 className="text-4xl ui-serif"> {price(propertyData?.price)}</h3>
            <h3 className="text-xl ui-serif mt-2">Pershkrim</h3>
            <p dangerouslySetInnerHTML={{ __html: propertyData?.desc }}></p>
            <div className="grid grid-cols-2 sm:grid-cols-3 mt-5 bg-zinc-100 py-2 px-4">
              <div>
                <h3 className="text-xl ui-serif">Statusi</h3>
                <p className="text-sm text-gray-500 dark:text-white">
                  {" "}
                  {findInObjects(
                    CreatePropertyStatuses,
                    "id",
                    "name",
                    propertyData.status
                  )}
                </p>
              </div>
              <div>
                <h3 className="text-xl ui-serif">Numer reference</h3>
                <p className="text-sm text-gray-500 dark:text-white">
                  {" "}
                  {propertyData.id}
                </p>
              </div>
              {isNotNullOrUndefined(propertyData.rooms) && (
                <div>
                  <h3 className="text-xl ui-serif">Dhoma</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {" "}
                    {findInObjects(
                      PropertyRooms,
                      "id",
                      "name",
                      propertyData.rooms
                    )}
                  </p>
                </div>
              )}
              {isNotNullOrUndefined(propertyData.baths) && (
                <div>
                  <h3 className="text-xl ui-serif">WC</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {" "}
                    {propertyData.baths}
                  </p>
                </div>
              )}
              {isNotNullOrUndefined(propertyData.surface) && (
                <div>
                  <h3 className="text-xl ui-serif">Siperfaqe</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {" "}
                    {propertyData.surface}m2
                  </p>
                </div>
              )}
              <div>
                <h3 className="text-xl ui-serif">Adressa</h3>
                <p className="text-sm text-gray-500 dark:text-white">
                  {" "}
                  {propertyData.address},{" "}
                  {findInObjects(Cities, "id", "name", propertyData.city_id)}
                </p>
              </div>
              {isNotNullOrUndefined(propertyData.furniture) && (
                <div>
                  <h3 className="text-xl ui-serif">Mobilimi</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {" "}
                    {findInObjects(
                      PropertyFurnitures,
                      "id",
                      "name",
                      propertyData.furniture
                    )}
                  </p>
                </div>
              )}
              {isNotNullOrUndefined(propertyData.floor) && (
                <div>
                  <h3 className="text-xl ui-serif">Kati</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {propertyData.floor}
                  </p>
                </div>
              )}
              {isNotNullOrUndefined(propertyData.documents) && (
                <div>
                  <h3 className="text-xl ui-serif">Dokumentacioni</h3>
                  <p className="text-sm text-gray-500 dark:text-white">
                    {" "}
                    {findInObjects(
                      PropertyDocuments,
                      "id",
                      "name",
                      propertyData.documents
                    )}
                  </p>
                </div>
              )}
            </div>
            <h3 className="text-xl ui-serif mt-10">Vendndodhja ne harte</h3>
            <iframe
              title="address"
              className="mt-2"
              width="100%"
              height="500"
              src={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${mapAddress(
                propertyData.address,
                findInObjects(Cities, "id", "name", propertyData.city_id)
              )}&z=14&output=embed`}
            ></iframe>
          </div>
          <div className="col-span-12 md:col-span-5 lg:col-span-4 sticky top-20 h-fit">
            <div className="mb-5">
              {isNotNullOrUndefined(propertyData.agent) && (
                <div>
                  <ContactSeller
                    seller={propertyData.agent}
                    module="PROPERTIES"
                    description={`Pershendetje interesohem ne lidhje me pronen <a href="${window.location.href}" class="ck-link_selected"><strong>${propertyData.name}</strong></a>!`}
                    whatsapp={`Pershendetje ${propertyData.agent.name}, interesohem ne lidhje me pronen ${window.location.href} eshte ende e lire?`}
                  />
                </div>
              )}
            </div>
            {isNotNullOrUndefined(propertyData.agency) && (
              <ContactSeller
                seller={propertyData.agency}
                module="PROPERTIES"
                description={`Pershendetje interesohem ne lidhje me pronen <a href="${window.location.href}" class="ck-link_selected"><strong>${propertyData.name}</strong></a>!`}
                whatsapp={`Pershendetje ${propertyData.agency.name}, interesohem ne lidhje me pronen ${window.location.href} eshte ende e lire?`}
              />
            )}
            {isNotNullOrUndefined(propertyData.person) && (
              <ContactSeller
                seller={propertyData.person}
                module="PROPERTIES"
                description={`Pershendetje interesohem ne lidhje me pronen <a href="${window.location.href}" class="ck-link_selected"><strong>${propertyData.name}</strong></a>!`}
                whatsapp={`Pershendetje ${propertyData.person.name}, interesohem ne lidhje me pronen ${window.location.href} eshte ende e lire?`}
              />
            )}
          </div>
        </div>
        <div className="mx-auto max-w-screen-2xl py-5 px-2">
          <PropertiesCarousel
            properties={properties}
            title="Prona te ngjashme"
          />
        </div>
      </div>
      <PropertyModal
        open={open}
        setOpen={setOpen}
        images={propertyData.images_urls}
        propertyData={propertyData}
      />
    </>
  );
}

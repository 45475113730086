import Slider from '../../../components/front/slider/Slider';
import CarsCarousel from '../../../components/front/carousel/CarsCarousel';
import { useFetchHomeQuery } from '../../../store/cars/CarsStore';
import { SliderItmesType } from '../../../components/types/General';
import Loading from '../../../components/general/Loading';
import { useNavigate } from 'react-router-dom';

export default function Properties() {

    const navigate = useNavigate();

    const mySliderList: SliderItmesType = [
        {
            id: 1,
            name: "Mercedes AMG",
            description: "Shitet mercedes me vaj filtra te sapo bera",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/car-1.jpg',
        },
        {
            id: 2,
            name: "Shitet Lambo",
            description: "Shitet Lambo me vaj filtra te sapo bera",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/car-2.webp',
        },
        {
            id: 3,
            name: "Shitet BMW",
            description: "Shitet BMW me vaj filtra te sapo bera",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/car-3.jpg',
        }
    ];

    const brands = [
        {
            id: 1,
            name: "Mercedes",
            url: '/slider-1',
            imageUrl: '/demo/brands/benz.jpg',
        }, {
            id: 2,
            name: "BMW",
            imageUrl: '/demo/brands/bmw.jpg',
        }, {
            id: 169,
            name: "Audi",
            imageUrl: '/demo/brands/audi.jpg',
        }, {
            id: 4,
            name: "Porsche",
            imageUrl: '/demo/brands/porsche.jpg',
        }, {
            id: 5,
            name: "Lamborgini",
            imageUrl: '/demo/brands/lambo.jpg',
        }, {
            id: 6,
            name: "Ford",
            imageUrl: '/demo/brands/ford.jpg',
        }, {
            id: 7,
            name: "BMW",
            imageUrl: '/demo/brands/bmw.jpg',
        }, {
            id: 8,
            name: "Audi",
            imageUrl: '/demo/brands/audi.jpg',
        }, {
            id: 9,
            name: "Porsche",
            imageUrl: '/demo/brands/porsche.jpg',
        }, {
            id: 10,
            name: "Lamborgini",
            imageUrl: '/demo/brands/lambo.jpg',
        }, {
            id: 11,
            name: "Ford",
            imageUrl: '/demo/brands/ford.jpg',
        }, {
            id: 12,
            name: "Mercedes",
            url: '/slider-1',
            imageUrl: '/demo/brands/benz.jpg',
        }
    ];

    //#region rtk
    const { data, isFetching } = useFetchHomeQuery({});
    //#endregion

    if (isFetching) return <Loading />
    return (
        <>
            <div className='properties'>
                <Slider items={mySliderList} url='/autonjete/' btn='Detajet e makines' />
            </div>
            <div className="mx-auto max-w-screen-2xl">
                <div className="py-10 px-2 2xl:px-0">
                    <CarsCarousel cars={data} />
                </div>

                <div className='px-2 2xl:px-0'>
                    <h2 className="top-0 text-2xl md:text-3xl font-medium ui-serif py-2 dark:text-white">
                        Markat me te njohura
                    </h2>
                    <div className="grid grid-cols-2 gap-1 sm:gap-5 sm:grid-cols-6">
                        {brands.map(brand => {
                            return <img key={brand.id} className='cursor-pointer' src={brand.imageUrl} alt={brand.name} onClick={() => navigate(`/automjete/lista?make_id=${brand.id}`)} />
                        })}
                    </div>
                </div>
                <div className="py-10 px-2 2xl:px-0">
                    <CarsCarousel cars={data} />
                </div>
            </div>
        </>
    )
}

import { useEffect, useState } from "react";
import {
  useUpdateCarMutation,
  useUpdateImagePriorityMutation,
  useDeleteImageMutation,
  useAddPremiumMutation,
} from "../../../store/account/cars/accountCarsStore";
import { useDispatch } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import FileUpload from "../../../components/general/FileUpload";
import {
  CarCambios,
  CarFuels,
  CarMilages,
  CarPostingZones,
  Cities,
  CreatePropertyStatuses,
} from "../../../services/constants";
import SingleSelect from "../../../components/general/SingleSelect";
import { useParams } from "react-router-dom";
import EFInput from "../../../components/general/EFInput";
import CkEdit from "../../../components/general/CkEdit";
import {
  useFetchCarDataQuery,
  useFetchModelsByMakeQuery,
} from "../../../store/cars/CarsStore";
import SelectBox from "../../../components/general/SelectBox";
import CarMainCard from "../../../components/account/cards/CarMainCard";
import { useFetchCarEditQuery } from "../../../store/account/cars/accountCarsStore";
import Loading from "../../../components/general/Loading";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  compressImages,
  findInObjects,
  isNotNullOrUndefined,
} from "../../../services/helpers";
import DateTimePicker from "../../../components/general/DateTimePicker";

const pages = [
  { name: "Automjete", href: "/account/cars/dashboard", current: false },
  { name: "Detaje", href: "#", current: true },
];

export default function EditCar() {
  //#region variables
  const { id } = useParams();
  const dispatch = useDispatch();
  const tomorow = new Date();
  tomorow.setDate(tomorow.getDate() + 1);

  const newPremiumDefault = {
    premium_from: new Date(),
    premium_to: tomorow.toISOString(),
    minTo: tomorow.toISOString(),
    minFrom: new Date(),
  };
  //#endregion

  //#region rtk
  const [updateCar] = useUpdateCarMutation();
  const [updateImagePriority] = useUpdateImagePriorityMutation();
  const [deleteImage] = useDeleteImageMutation();
  const [addPremium] = useAddPremiumMutation();
  const { data, refetch } = useFetchCarEditQuery({ id: id });
  //#endregion

  //#region states
  const [formData, setFormData] = useState<any>({});
  const [newPremium, setNewPremium] = useState<any>(newPremiumDefault);
  const [errors, setErrors] = useState<any>();
  //#endregion

  const { data: carData } = useFetchCarDataQuery({ allBrands: true });
  const { data: models } = useFetchModelsByMakeQuery(
    { make_id: formData.make_id, allModeles: true },
    { skip: formData?.make_id ? formData.make_id < 0 : true }
  );
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    const fields = [
      "city_id",
      "status_id",
      "make_id",
      "model_id",
      "fuel_id",
      "cambio_id",
      "milage",
    ];
    let value = e;
    if (fields.includes(key)) {
      value = e;
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeEditor = (event: any, editor: any) => {
    setFormData({
      ...formData,
      description: editor.getData(),
    });
  };

  const handleChangeImagePriority = async (id: number) => {
    const params = {
      propertyId: formData.id,
      imageId: id,
    };
    const response: any = await updateImagePriority(params);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
      refetch();
    }
  };

  const handleDeleteImage = async (id: number) => {
    const response: any = await deleteImage({
      propertyId: formData.id,
      imageId: id,
    });
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U fshi me sukses!", visible: true }));
      refetch();
    }
  };

  //Submit property
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response: any = await updateCar(formData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleFilesChange = async (e: any) => {
    const imagesFull = await compressImages(e);
    const imagesThumbnail = await compressImages(e, 650);
    setFormData({
      ...formData,
      imagesFull: imagesFull,
      imagesThumbnail: imagesThumbnail,
    });
  };
  const onChangeFromDate = (e: any) => {
    const date = new Date(e);
    date.setDate(date.getDate() + 1);
    if (newPremium.premium_to < date.toISOString()) {
      setNewPremium({
        ...newPremium,
        premium_from: e,
        minTo: date.toISOString(),
        premium_to: date.toISOString(),
      });
    } else {
      setNewPremium({
        ...newPremium,
        premium_from: e,
        minTo: date.toISOString(),
      });
    }
  };

  const addNewPremium = async () => {
    const insertData = {
      carId: formData.id,
      premium_id: newPremium.premium_id,
      premium_from: newPremium.premium_from,
      premium_to: newPremium.premium_to,
    };

    const response: any = await addPremium(insertData);

    if (response.data?.status) {
      dispatch(showNotification({ msg: response.data.message, visible: true }));
      setNewPremium(newPremiumDefault);
      refetch();
    }

    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);

  useEffect(() => {
    if (isNotNullOrUndefined(data)) {
      setFormData(data);
    }
  }, [data]);

  if (!data) return <Loading />;

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "status_id")}
                items={CreatePropertyStatuses}
                defaultValue={data.status_id}
                name="status_id"
                label="Statusi"
                validations={errors}
              />
            </div>
            <div>
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "make_id")}
                items={carData?.makes}
                defaultValue={data.make_id}
                name="make_id"
                label="Marka e makines"
                validations={errors}
              />
            </div>
            {formData?.make_id > 0 && (
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "model_id")}
                name="model_id"
                items={models?.models ?? []}
                defaultValue={data.model_id}
                label="Zgjidh modelin"
                validations={errors}
              />
            )}
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "fuel_id")}
                items={CarFuels}
                label="Karburanti"
                name="fuel_id"
                defaultValue={data.fuel_id}
                validations={errors}
              />
            </div>
            <EFInput
              min={1950}
              onChange={handleChange}
              type="number"
              name="year"
              label="Viti"
              defaultValue={data.year}
              placeholder="Viti i prodhimit"
              validations={errors}
            />
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "milage")}
                items={CarMilages}
                label="KM"
                name="milage"
                defaultValue={data.milage}
                validations={errors}
              />
            </div>
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "cambio_id")}
                items={CarCambios}
                label="Kambio"
                name="cambio_id"
                defaultValue={data.cambio_id}
                validations={errors}
              />
            </div>
            <EFInput
              onChange={handleChange}
              type="number"
              name="engine"
              label="Motori"
              defaultValue={data.engine}
              placeholder="2.2"
              step=".1"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              name="potenca"
              label="Kuaj fuqi"
              defaultValue={data.potenca}
              placeholder="125"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              name="consum"
              label="Konsumi"
              defaultValue={data.consum}
              placeholder="6.5"
              step=".1"
              validations={errors}
            />
            <div>
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "city_id")}
                items={Cities}
                name="city_id"
                defaultValue={data.city_id}
                label="Zgjidh qytetin"
                validations={errors}
              />
            </div>
            <EFInput
              onChange={handleChange}
              type="number"
              name="price"
              defaultValue={data.price}
              label="Cmimi"
              placeholder="Vendos cmimin ne euro"
              validations={errors}
            />
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <CkEdit
                onChange={handleChangeEditor}
                label="Pershkrimi"
                name="description"
                defaultValue={data.description}
                validations={errors}
              />
            </div>
          </div>
          <div className="col-span-full">
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-4">
              Fotot e prones
            </label>
            <p>
              Per te percaktuar se cila foto do te shfaqet ne fillim tek prona
              klikoni mbi foto.
            </p>
            <div className="grid grid-cols-4 mt-2 gap-1">
              {data?.images?.map((file: any, index: number) => (
                <div key={index} className={`relative`}>
                  <img
                    onClick={() => handleChangeImagePriority(file.id)}
                    src={file.url}
                    alt={file.name}
                    className={`m-1 object-cover w-full aspect-video ${
                      file.priority === 1 ? "border border-zinc-500 p-1" : ""
                    }`}
                  />
                  <button
                    onClick={() => handleDeleteImage(file.id)}
                    className="absolute top-3 right-3 p-1 bg-neutral-800 rounded-full"
                  >
                    <XMarkIcon className="h-6 w-6 text-white" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
            Ngarko fotot e automjetit
          </label>
          <div className="my-2 col-span-full">
            <FileUpload
              onFilesChange={(e) => handleFilesChange(e)}
              cols={3}
              validations={errors}
            />
          </div>
          <div className="grid grid-col-3"></div>
        </div>
        <div className="col-span-4">
          <span className="text-sm">
            Kjo eshte se si postimi juaj do te shfaqet new webin tone. Mundojuni
            ti plotesoni dhe pershtasni sa me mire te dhenat ne menyre qe
            postimi te dali i rregullt.
          </span>
          <CarMainCard
            carData={{
              images: formData.imagesThumbnail,
              make: carData?.makes?.find((x: any) => x.id === formData.make_id)
                ?.name,
              model: models?.models?.find(
                (x: any) => x.id === formData?.model_id
              )?.name,
              price: formData.price,
              year: formData.year,
              status_id: formData.status_id,
              engine: formData.engine,
              fuel: CarFuels.find((x: any) => x.id === formData?.fuel_id)?.name,
              cambio: CarCambios.find((x: any) => x.id === formData?.cambio_id)
                ?.name,
              potenca: formData.potenca,
              milage: CarMilages.find((x: any) => x.id === formData?.milage)
                ?.name,
              description: formData.description,
            }}
          />
        </div>
      </div>
      <button
        type="button"
        className="mt-2 mb-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
        onClick={handleSubmit}
      >
        Save
      </button>
      <div>
        <div className="grid grid-cols-12 gap-4 mt-4 mb-4">
          <div className="col-span-12">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) =>
                    setNewPremium({ ...newPremium, premium_id: e })
                  }
                  items={CarPostingZones.filter((x) => x.id > 0)}
                  defaultValue={newPremium.premium_id}
                  name="premium_id"
                  label="Zona e postimit"
                  validations={errors}
                />
              </div>
              <DateTimePicker
                showTimeSelect={true}
                minDate={newPremium.minFrom}
                name="premium_from"
                label="Premium nga data"
                defaultValue={newPremium.premium_from}
                onChange={onChangeFromDate}
                validations={errors}
              />
              <DateTimePicker
                minDate={newPremium.minTo}
                name="premium_to"
                label="Premium deri ne daten"
                defaultValue={newPremium.premium_to}
                onChange={(e: any) =>
                  setNewPremium({ ...newPremium, premium_to: e })
                }
                validations={errors}
              />
            </div>
            <button
              type="button"
              className="mt-2 mb-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
              onClick={addNewPremium}
            >
              Shto
            </button>
            <div className="overflow-auto" style={{ minHeight: 100 }}>
              <table className="min-w-full divide-y divide-neutral-300">
                <thead className="bg-neutral-300 dark:bg-neutral-800 dark:text-white sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Premium category
                    </th>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Data e fillimit
                    </th>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Data e mbarimit
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-300 dark:divide-neutral-800 bg-white dark:bg-neutral-800">
                  {data?.premiums.map((item: any) => (
                    <tr
                      key={item.id}
                      className={`even:bg-neutral-100 dark:even:bg-neutral-900`}
                    >
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {findInObjects(
                          CarPostingZones,
                          "id",
                          "name",
                          item.premium_id
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {item.from}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {item.to}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { CameraIcon, HeartIcon, ShareIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import { useFetchCarListQuery, useFetchCarQuery, useSaveCarMutation } from "../../../store/cars/CarsStore";
import { useParams } from 'react-router-dom';
import { carTitle, copyUrl, findInObjects, isLogedIn, isNotNullOrUndefined, isNumeric, price, removeCookie } from '../../../services/helpers';
import NotFound from '../../NotFound'
import Loading from '../../../components/general/Loading'
import { CarMilages, CarCambios, CarFuels } from '../../../services/constants';
import ImagesModal from './components/ImagesModal';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../store/main/MainSlice';
import PropertyContactModal from '../../../components/general/contacts/PropertyContactModal';
import ContactSeller from '../../../components/general/contacts/ContactSeller';
import CarsCarousel from '../../../components/front/carousel/CarsCarousel';

export default function CarDetails() {

    //#region constants
    const dispatch = useDispatch();
    const { id } = useParams();
    //#endregion

    //#region rtk
    const { data: { data: cars } = {} } = useFetchCarListQuery({});
    const { data, isFetching } = useFetchCarQuery({ id: id });
    const [saveCar] = useSaveCarMutation();
    let images = data?.images_urls;
    //#endregion  

    //#region state
    const [open, setOpen] = useState<boolean>(false);
    //#endregion

    //#region refs
    const refContactModal = useRef<any>(null);
    //#endregion

    //#region functions
    if (!isNumeric(id)) {
        return <NotFound />
    }
    const handleSaveCar = async () => {

        try {
            if (isLogedIn()) {
                const result: any = await saveCar({ ids: [data.id] });
                if (result?.data?.status) {
                    dispatch(showNotification({ msg: result.data.message, visible: true }));
                }
                if (result?.error?.status === 401) {
                    dispatch(showNotification({ msg: "Please login first!", visible: true }));
                    removeCookie('authToken');
                    window.location.reload();
                }
            } else {
                dispatch(showNotification({ msg: "Please login first!", visible: true }));
            }
        } catch (error) { }
    };

    const handleCopyUrl = () => {
        copyUrl();
        dispatch(showNotification({ msg: "Linku u kopjua me sukses!", visible: true }));
    };
    //#endregion

    if (isFetching) {
        return <Loading />;
    } else {
        if (!data?.id) {
            return <NotFound />
        }
    }

    return (
        <>
            <PropertyContactModal propertyName={data?.name} forwardedRef={refContactModal} />
            <div className={`mx-auto max-w-screen-2xl`}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-2 relative">
                    {
                        images?.length === 1 &&
                        <div className={`mt-1 col-span-12`}>
                            <div className='col-span-8 col-span-6 col-span-4'></div>
                            <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[0]?.url} alt="" />
                        </div>
                    }
                    {
                        images?.length === 2 &&
                        <>
                            <div className={`mt-1 col-span-6`}>
                                <div className='col-span-8 col-span-6 col-span-4'></div>
                                <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[0]?.url} alt="" />
                            </div>
                            <div className={`mt-1 col-span-6`}>
                                <div className='col-span-8 col-span-6 col-span-4'></div>
                                <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[1]?.url} alt="" />
                            </div>
                        </>
                    }
                    {images?.length > 2 &&
                        <>
                            <div className={`mt-1 col-span-${images?.length < 5 ? 8 : 6}`}>
                                <div className='col-span-8 col-span-6 col-span-4'></div>
                                <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[0]?.url} alt="" />
                            </div>
                            <div className={`hidden md:block col-span-${images?.length < 5 ? 4 : 6}`}>
                                {images?.length > 2 &&
                                    <div className={`grid grid-cols-${images?.length < 5 ? '1 gap-1' : '2 gap-2'} my-1`}>
                                        <div>
                                            <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[1]?.url} alt="" />
                                        </div>
                                        <div>
                                            <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[2]?.url} alt="" />
                                        </div>
                                    </div>
                                }
                                {images?.length > 4 &&
                                    <div className="grid grid-cols-2 gap-2 my-1">
                                        <div>
                                            <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[3]?.url} alt="" />
                                        </div>
                                        <div>
                                            <img onClick={() => setOpen(true)} className="aspect-[16/10] w-full object-cover" src={images[4]?.url} alt="" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    <div className='absolute bottom-10 gap-2 mx-auto max-w-screen-2xl w-full'>
                        <div className='grid grid-cols-2'>
                            <div className='px-2'>
                                <button
                                    onClick={handleSaveCar}
                                    type="button"
                                    className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-neutral-800 hover:text-white"
                                >
                                    <HeartIcon className={`-ml-0.5 h-5 w-5 ${data.liked ? 'fill-black' : ''}`} aria-hidden="true" />
                                </button>
                                <button
                                    onClick={handleCopyUrl}
                                    type="button"
                                    className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-neutral-800 hover:text-white"
                                >
                                    <ShareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className='text-right px-2'>
                                <button
                                    type="button"
                                    onClick={() => setOpen(true)}
                                    className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-black bg-opacity-75 px-2.5 py-1.5 text-sm font-semibold text-white dark:text-zinc-800 shadow-sm hover:bg-white hover:text-gray-800"
                                >
                                    <CameraIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    {images?.length} Foto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 relative dark:text-white mt-10 px-2 sm:px-0">
                    <div className='col-span-12 md:col-span-7 lg:col-span-8'>
                        <h3 className='text-4xl ui-serif'>{carTitle(data)}</h3>
                        <h3 className='text-4xl ui-serif'> {price(data?.price)}</h3>
                        <div className='grid grid-cols-2 sm:grid-cols-3 mt-5'>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Marka</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.make.name}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Modeli</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.model.name}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Viti i prodhimit</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.year}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Motorri</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.engine}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Fuqia motorrike</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.potenca}HP</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Kambio</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {findInObjects(CarCambios, "id", "name", data.cambio_id)}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Kilometra</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {findInObjects(CarMilages, "id", "name", data.milage)} KM</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Karburanti</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {findInObjects(CarFuels, "id", "name", data.fuel_id)}</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Konsumi mesatar</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {data.consum} L/100KM</p>
                            </div>
                            <div className='ml-2 mt-3'>
                                <h3 className='text-xl ui-serif'>Shitesi</h3>
                                <p className="text-sm text-gray-500 dark:text-white"> {isNotNullOrUndefined(data.agency_id) ? 'Koncensionar' : 'Individ'}</p>
                            </div>
                        </div>
                        <h3 className='text-xl ui-serif mt-2'>Pershkrim</h3>
                        <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                    </div>
                    <div className='col-span-12 md:col-span-5 lg:col-span-4 sticky top-20 h-fit'>
                        {isNotNullOrUndefined(data.agency) &&
                            <ContactSeller
                                seller={data.agency}
                                module="CARS" description={`Pershendetje interesohem ne lidhje me automjetin <a href="${window.location.href}" class="ck-link_selected"><strong>${carTitle(data)}</strong></a>!`}
                                whatsapp={`Pershendetje ${data.agency.name}, interesohem ne lidhje me automjetin ${window.location.href} eshte ende i disponueshem?`}
                            />
                        }
                        {isNotNullOrUndefined(data.client) &&
                            <ContactSeller
                                seller={data.client}
                                module="CARS" description={`Pershendetje interesohem ne lidhje me automjetin <a href="${window.location.href}" class="ck-link_selected"><strong>${carTitle(data)}}</strong></a>!`}
                                whatsapp={`Pershendetje ${data.client.name}, interesohem ne lidhje me automjetin ${window.location.href} eshte ende i disponueshem?`}
                            />
                        }
                    </div>
                </div>
                <div className="mx-auto max-w-screen-2xl py-5 px-2">
                    <CarsCarousel cars={cars} title="Postime te ngjashme" />
                </div>
            </div>
            <ImagesModal open={open} setOpen={setOpen} images={data.images_urls} data={data} />
        </>
    )
}

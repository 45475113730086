import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../src/services/apiHeaders";

export const carsApi = createApi({
  reducerPath: "cars_r",
  baseQuery: fetchBaseQuery({
    //   baseUrl: process.env.API_SERVER_URL + process.env.API_V1,
    baseUrl: process.env.REACT_APP_API_BASE_URL + "cars",
    fetchFn: async (...args) => {
      return fetch(...args);
    },
    prepareHeaders(headers) {
      return getHeader(headers);
    },
  }),

  endpoints: (builder) => ({
    fetchCarData: builder.query({
      query: (params) => {
        return {
          url: "get-car-data",
          method: "GET",
          params: params,
        };
      },
    }),
    fetchModelsByMake: builder.query({
      query: (params) => {
        return {
          url: "get-car-model-by-make",
          method: "GET",
          params: params,
        };
      },
    }),
    fetchHome: builder.query({
      query: () => {
        return {
          url: "home",
          method: "GET",
        };
      },
    }),
    fetchCarList: builder.query({
      query: (params) => {
        return {
          url: "list",
          method: "GET",
          params: params,
        };
      },
    }),
    fetchCar: builder.query({
      query: (params) => {
        return {
          url: "car",
          method: "GET",
          params: params,
        };
      },
    }),
    fetchCarAgencies: builder.query({
      query: (params) => {
        return {
          url: "agencies",
          method: "GET",
          params: params,
        };
      },
    }),
    fetchAgency: builder.query({
      query: (params) => {
        return {
          url: "agency",
          method: "GET",
          params: params,
        };
      },
    }),
    saveCar: builder.mutation({
      query: (body) => ({
        url: "/account/save-car",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useFetchCarDataQuery,
  useFetchModelsByMakeQuery,
  useFetchCarListQuery,
  useFetchHomeQuery,
  useFetchCarQuery,
  useFetchCarAgenciesQuery,
  useFetchAgencyQuery,
  useSaveCarMutation,
} = carsApi;

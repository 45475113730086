import React from "react";
import AccountSidebar from "../AccountSidebar";
import NotificationAlert from "../../../general/NotificationAlert";
import { LayoutSelectorProps, NavigationItem } from "../../../types/General";

import {
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  HomeModernIcon,
  ListBulletIcon,
  MinusIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

const SystemLayout: React.FC<LayoutSelectorProps> = ({ children }) => {
  //#region constants
  const location = useLocation();

  let navigation: NavigationItem[] = [
    {
      id: 1,
      name: "Dashboard",
      href: "/system",
      icon: HomeIcon,
      role: [],
      current: location.pathname === "/system",
    },
    {
      id: 2,
      name: "Users",
      href: "/system/users",
      icon: UserIcon,
      role: [],
      current: location.pathname === "/system/users",
    },
    {
      id: 7,
      name: "Properties",
      href: "/system/properties",
      icon: HomeModernIcon,
      role: [],
      current: location.pathname.includes("/system/properties"),
      children: [
        {
          id: 1,
          name: "Property Agencies",
          href: "/system/properties/agencies",
          role: [],
          current: location.pathname === "/system/properties/agencies",
          icon: MinusIcon,
        },
        {
          id: 2,
          name: "Property agents",
          href: "/system/properties/agents",
          role: [],
          current: location.pathname === "/system/properties/agents",
          icon: MinusIcon,
        },
        {
          id: 2,
          name: "Properties",
          href: "/system/properties",
          role: [],
          current: location.pathname === "/system/properties",
          icon: MinusIcon,
        },
      ],
    },
    {
      id: 4,
      name: "Shto makine",
      href: "/account/cars/agency/create",
      icon: PlusCircleIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/create",
    },
    {
      id: 5,
      name: "Lista e makinave",
      href: "/account/cars/agency/car-list",
      icon: ListBulletIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/car-list",
    },
    {
      id: 6,
      name: "Makinat e ruajtura",
      href: "/account/cars/agency/saved",
      icon: HeartIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/saved",
    },
    {
      id: 7,
      name: "Mesazhe",
      href: "/account/cars/agency/contacts",
      icon: ChatBubbleBottomCenterTextIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/contacts",
      children: [
        {
          id: 7,
          name: "Mesazhe",
          href: "/account/cars/agency/contacts",
          icon: ChatBubbleBottomCenterTextIcon,
          role: [],
          current: location.pathname === "/account/cars/agency/contacts",
        },
      ],
    },
    { id: 8, name: "Reports", href: "#", icon: ChartPieIcon, role: [] },
  ];
  //#endregion

  return (
    <>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </>
  );
};

export default SystemLayout;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../services/apiHeaders";

export const accountApi: any = createApi({
    reducerPath: "account",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "account",
        fetchFn: async (...args) => {
            const response = await fetch(...args);
            if (response.status === 401) {
                window.location.href = "/login";
            }
            if (response.status === 403) {
                alert('Ju nuk keni te drejta per te aksesuar kete url!')
                window.history.back();
            }
            return response;
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),


    endpoints: (builder) => ({
        deleteContact: builder.mutation({
            query: (body) => {
                return {
                    url: '/delete-contact',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        fetchContacts: builder.query({
            query: (params) => {
                return {
                    url: "contacts",
                    method: "GET",
                    params: { module: window.location.href, ...params }
                };
            },
        }),
        fetchTransactions: builder.query({
            query: (params) => {
                return {
                    url: "transactions",
                    method: "GET",
                    params: params
                };
            },
        }),
    }),
});

export const {
    useFetchContactsQuery,
    useDeleteContactMutation,
    useFetchTransactionsQuery
} = accountApi;

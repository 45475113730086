import { RadioGroup } from '@headlessui/react';
import { isNotNullOrUndefined } from '../../services/helpers';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function EFRadioGroup(
  { items, label, onChange, selectedItem, name, cols = 2, smCols = 3 }
    :
    {
      items: any;
      label?: string;
      name: string;
      onChange: any;
      selectedItem?: any;
      cols?: number;
      smCols?: number;
    }
) {

  return (
    <RadioGroup onChange={(e: any) => onChange(e, name)} className="mt-3">
      {isNotNullOrUndefined(label) &&
        <label
          htmlFor={label}
          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
        >
          {label}
        </label>
      }
      <div className={`grid gap-3 grid-cols-${cols} sm:grid-cols-${smCols}`} id={label}>
        {items.map((option: any) => (
          <RadioGroup.Option
            key={option.name}
            value={option.id}
            className={({ checked }) =>
              classNames(
                (selectedItem === option.id && !checked) || checked
                  ? 'dark:text-white border-slate-800 dark:border-zinc-500'
                  : 'dark:text-white border-slate-300 dark:border-neutral-800',
                'cursor-pointer  focus:outline-none flex items-center justify-center rounded-md py-2 px-3 text-sm font-semibold sm:flex-1 border'
              )
            }
          >
            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

import {
  EnvelopeIcon,
  EyeIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { isNotNullOrUndefined } from "../../services/helpers";

export default function EFInput({
  label,
  type = "text",
  placeholder,
  name,
  defaultValue,
  onChange,
  validations,
  hasIcon = false,
  required,
  className,
  refValue,
  iconColor = "text-white", // Added default icon color
  ...props // This will collect any other props passed to the component
}: {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: any, name: string) => void;
  defaultValue?: any;
  validations?: any;
  hasIcon?: boolean;
  required?: boolean;
  iconColor?: string; // Icon color prop (default value set to "text-white")
  refValue?: any;
  [key: string]: any; // To accept any other dynamic props
}) {
  const [mode, setMode] = useState<string>(type);

  const show = async (e: any) => {
    if (mode === "password") {
      setMode("text");
    } else {
      setMode("password");
    }
  };

  const Icon = () => {
    if (name === "email") {
      return (
        <div className="rounded-sm pointer-events-none inset-y-0 left-0 flex items-center px-2 bg-zinc-900">
          <EnvelopeIcon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
      );
    }
    if (name === "password" || name === "confirmPassword") {
      return (
        <div
          className="rounded-sm inset-y-0 left-0 flex items-center px-2 bg-zinc-900"
          onClick={show}
        >
          <EyeIcon
            className={`h-5 w-5 ${iconColor} cursor-pointer`}
            aria-hidden="true"
          />
        </div>
      );
    }
    if (name === "phone") {
      return (
        <div className="rounded-sm inset-y-0 left-0 flex items-center px-2 bg-zinc-900">
          <PhoneIcon
            className={`h-5 w-5 ${iconColor} cursor-pointer`}
            aria-hidden="true"
          />
        </div>
      );
    }
    if (name === "username" || name === "name") {
      return (
        <div className="rounded-sm inset-y-0 left-0 flex items-center px-2 bg-zinc-900">
          <IdentificationIcon
            className={`h-5 w-5 ${iconColor} cursor-pointer`}
            aria-hidden="true"
          />
        </div>
      );
    }
    if (name === "website") {
      return (
        <span className="rounded-sm flex select-none items-center px-2 text-gray-500 sm:text-sm bg-zinc-900 mr-2 text-white">
          http://
        </span>
      );
    }
    return null;
  };

  return (
    <div>
      {isNotNullOrUndefined(label) && (
        <label
          htmlFor={name}
          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-white ${className}`}
        >
          {label} <span className="text-red-600">{required ? "*" : ""}</span>
        </label>
      )}
      <div className="flex border border-slate-300 dark:border-neutral-800 rounded h-9">
        {hasIcon && <Icon />}
        <input
          id={name}
          onInput={(e: any) => onChange?.(e, name)}
          placeholder={placeholder}
          type={mode}
          defaultValue={defaultValue}
          className={`w-full dark:bg-zinc-900 px-3 py-2 ${
            hasIcon ? "pl-1" : "rounded-md"
          } text-sm dark:text-white shadow-sm placeholder-slate-400 dark:placeholder-white focus:outline-none focus:border-gray-500 invalid:border-red-500 invalid:text-red-600`}
          ref={refValue}
          {...props} // Spread the rest of the props to the input element
        />
      </div>
      {validations && name && validations[name] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {validations[name]}
        </p>
      )}
    </div>
  );
}

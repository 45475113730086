export const CarStatuses = [
  { id: 1, name: "Makina ne shitje" },
  { id: 2, name: "Makina me qera" },
];

export const CarFuels = [
  { id: 1, name: "Nafte" },
  { id: 2, name: "Benzine" },
  { id: 3, name: "Benzine-Gaz" },
  { id: 4, name: "Elektrike" },
];
export const CarCambios = [
  { id: 1, name: "Manual" },
  { id: 2, name: "Automatic" },
  { id: 3, name: "TipTronic" },
];
export const CarSellerTypes = [
  { id: 1, name: "Koncesionar" },
  { id: 2, name: "Privat" },
];
export const CarMilages = [
  { id: 1, name: "0-50 000" },
  { id: 2, name: "50 000-100 000" },
  { id: 3, name: "100 000-150 000" },
  { id: 4, name: "150 000 - 200 000" },
  { id: 5, name: "200 000 - 250 000" },
  { id: 6, name: "250 000 - 300 000" },
  { id: 7, name: "300 000 +" },
];

export const PropertyPostingZones = [
  { id: -1, name: "Pa pagese" },
  { id: 1, name: "Premium 1 - (6 kredite)" },
  { id: 2, name: "Premium 2 - (4 kredite)" },
  { id: 3, name: "Premium 3 - (2 kredite)" },
  { id: 4, name: "Premium 4 - (1 kredit)" },
];
export const CarPostingZones = [
  { id: -1, name: "Pa pagese" },
  { id: 1, name: "Premium 1 - (4 kredite)" },
  { id: 2, name: "Premium 2 - (3 kredite)" },
  { id: 3, name: "Premium 3 - (2 kredite)" },
  { id: 4, name: "Premium 4 - (1 kredit)" },
];
export const PropertyStatuses = [
  { id: 1, name: "Prona ne shitje" },
  { id: 2, name: "Prona me qera" },
];
export const CreatePropertyStatuses = [
  { id: 1, name: "Shitet" },
  { id: 2, name: "Jepet me qera" },
];

export const PropertyTypes = [
  { id: 1, name: "Apartament" },
  { id: 2, name: "Toke" },
  { id: 3, name: "Zyre" },
  { id: 4, name: "Vile" },
];
export const PropertyRooms = [
  { id: 1, name: "Garzioniere" },
  { id: 2, name: "Apartament 1+1" },
  { id: 3, name: "Apartament 2+1" },
  { id: 4, name: "Apartament 3+1" },
  { id: 5, name: "Apartament 4+1" },
  { id: 6, name: "Apartament 5+" },
];
export const PropertyBathrooms = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3+" },
];
export const PropertyFurnitures = [
  { id: 1, name: "I pa mobiluar" },
  { id: 2, name: "Pjeserisht i mobiluar" },
  { id: 3, name: "Totalisht i mobiluar" },
];
export const PropertyDocuments = [
  { id: 1, name: "Me hipoteke" },
  { id: 2, name: "Pa hipoteke" },
  { id: 3, name: "Ne proces legalizimi" },
];

export const Cities = [
  { id: 1, name: "Berat" },
  { id: 2, name: "Bilisht" },
  { id: 3, name: "Bulqizë" },
  { id: 4, name: "Burrel" },
  { id: 5, name: "Cërrik" },
  { id: 6, name: "Çorovodë" },
  { id: 7, name: "Delvinë" },
  { id: 8, name: "Divjakë" },
  { id: 9, name: "Durrës" },
  { id: 10, name: "Elbasan" },
  { id: 11, name: "Ersekë" },
  { id: 12, name: "Fier" },
  { id: 13, name: "Fushë-Krujë" },
  { id: 14, name: "Gjirokastër" },
  { id: 15, name: "Gramsh" },
  { id: 16, name: "Himare" },
  { id: 17, name: "Kavajë" },
  { id: 18, name: "Koplik" },
  { id: 19, name: "Korçë" },
  { id: 20, name: "Krujë" },
  { id: 21, name: "Kuçovë" },
  { id: 22, name: "Kukës" },
  { id: 23, name: "Laç" },
  { id: 24, name: "Maliq" },
  { id: 25, name: "Cerrave" },
  { id: 26, name: "Lezhë" },
  { id: 27, name: "Librazhd" },
  { id: 28, name: "Lushnjë" },
  { id: 29, name: "Milot" },
  { id: 30, name: "Orikum" },
  { id: 31, name: "Peqin" },
  { id: 32, name: "Përmet" },
  { id: 33, name: "Peshkopi" },
  { id: 34, name: "Pogradec" },
  { id: 35, name: "Pukë" },
  { id: 36, name: "Roskovec" },
  { id: 37, name: "Rrëshen" },
  { id: 38, name: "Rrogozhinë" },
  { id: 39, name: "Sarandë" },
  { id: 40, name: "Shkodër" },
  { id: 41, name: "Tepelenë" },
  { id: 42, name: "Tiranë" },
  { id: 43, name: "Vlorë" },
  { id: 44, name: "Vorë" },
];

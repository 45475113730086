import { useEffect, useState, useRef, Fragment } from "react";
import {
  getUrlParams,
  price,
  scrollToDiv,
  truncateString,
} from "../../../services/helpers";
import Pagination from "../../../components/general/Pagination";
import { broadcrumb } from "../../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import Loading from "../../../components/general/Loading";
import { useFetchPropertiesSavedQuery } from "../../../store/account/properties/accountPropertiesStore";
import { useSavePropertyMutation } from "../../../store/properties/PropertiesStore";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useCalculateHeight } from "../../../hooks/calculateHeight";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Pronat e ruajtura", href: "#", current: true },
];

export default function PropertySaves() {
  //#region costants
  const params = getUrlParams();
  const dispatch = useDispatch();
  const height = useCalculateHeight(150);
  //#endregion

  //#region rtk
  const { data, isFetching, refetch } = useFetchPropertiesSavedQuery(params);
  const [saveProperty] = useSavePropertyMutation();
  //#endregion

  //#region refs
  const refCheckbox = useRef<any>(null);
  const cancelButtonRef = useRef(null);
  //#endregion

  //#region states
  const [list, setList] = useState([]);
  const [page, setPage] = useState(params);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(page);
    scrollToDiv("properties", 80);
  };

  const handleRemoveProperty = async (id: any) => {
    await saveProperty({ ids: [id] });
    refetch();
  };
  const handleRemoveProperties = async () => {
    setOpen(false);
    setSelectedRows([]);
    await saveProperty({ ids: selectedRows });
    refetch();
  };

  function toggleAll() {
    setSelectedRows(checked ? [] : data?.data.map((item: any) => item.id));
    setChecked(!checked);
  }

  function handleSelect(e: any, agent: any) {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, agent.id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== agent.id));
    }
  }
  const ConfirmModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto sm:pl-64">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 "
                      >
                        Konfirmoni veprimin
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm">Jeni te sigurt?</p>
                        <p className="text-sm">
                          Duke konfirmuar te gjitha pronat e selektuara do te
                          hiqen nga te preferuarat!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-neutral-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700 sm:w-auto"
                      onClick={handleRemoveProperties}
                    >
                      Konfirmo
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Anullo
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);
  useEffect(() => {
    setList(data?.data);
  }, [data]);
  //#endregion

  if (isFetching && list?.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <div id="properties">
        <ConfirmModal />
        <div className="relative sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {selectedRows.length > 0 && (
              <div className="absolute left-0 top-3 z-20 flex items-center space-x-3 bg-white sm:left-12">
                <button
                  onClick={() => setOpen(true)}
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <TrashIcon
                    className="h-5 w-5 text-red-600 mr-2"
                    aria-hidden="true"
                  />
                  Fshi nga te preferuarat
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="overflow-auto" style={{ height: height }}>
          <table className="min-w-full divide-y divide-neutral-300 dark:text-white">
            <thead className="bg-neutral-300 dark:bg-neutral-800  sticky top-0 z-10">
              <tr>
                <th
                  scope="col"
                  className="border-b border-neutral-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  <input
                    type="checkbox"
                    className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-neutral-300 focus:ring-zinc-800"
                    ref={refCheckbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                <th
                  scope="col"
                  className="border-b border-neutral-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Titulli i prones
                </th>
                <th
                  scope="col"
                  className="border-b border-neutral-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Data e krijimit
                </th>
                <th
                  scope="col"
                  className="border-b border-neutral-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Cmimi
                </th>
                <th
                  scope="col"
                  className="border-b border-neutral-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="border-b border-neutral-300  text-right py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Veprime
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-300 dark:divide-neutral-800 bg-white dark:bg-neutral-700">
              {list?.map((property: any) => (
                <tr
                  key={property.id}
                  className="even:bg-neutral-100 dark:even:bg-neutral-800"
                >
                  <td className="relative px-7 sm:w-12 sm:px-6">
                    {selectedRows.includes(property.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-neutral-800 rounded-sm" />
                    )}
                    <input
                      type="checkbox"
                      className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-neutral-300 focus:ring-zinc-800"
                      value={property.id}
                      checked={selectedRows.includes(property.id)}
                      onChange={(e) => handleSelect(e, property)}
                    />
                  </td>
                  <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="flex items-center">
                      <div className="h-16 w-32 flex-shrink-0">
                        <img
                          className="h-16 w-32 rounded-sm object-cover"
                          src={property.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium">{property.name}</div>
                        <div className="mt-1">
                          {truncateString(property.desc, 50)}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm">
                    <div>{property.created}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm">
                    <div>{price(property.price)}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm">
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {property.status === 1 ? "Shitet" : "Qera"}
                    </span>
                  </td>
                  <td className="whitespace-nowrap flex px-3 py-5 text-sm float-right">
                    <button
                      onClick={() => handleRemoveProperty(property.id)}
                      type="button"
                      className="inline-flex items-center rounded bg-red-700 px-2 py-1 text-sm font-semibold  ml-1 ring-0 text-white"
                    >
                      Fshi
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={data?.current_page ?? 1}
        from={data?.from ?? 0}
        to={data?.to ?? 0}
        total={data?.total ?? 0}
        last={data?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  );
}

import { useState } from "react";
import { useFetchCarAgenciesQuery } from "../../../store/cars/CarsStore";
import Pagination from "../../../components/general/Pagination";
import { beautifyUrl, getUrlParams } from "../../../services/helpers";
import Loading from "../../../components/general/Loading";
import { useNavigate } from "react-router-dom";

export default function CarAgencies() {
  //#region constants
  const navigate = useNavigate();
  const params = getUrlParams();
  //#endregion

  //#region states
  const [render, setRender] = useState(false);
  const [state, setState] = useState<string>("");
  //#endregion

  //#region rtk
  const { data, isFetching } = useFetchCarAgenciesQuery(params);
  //#endregion

  if (isFetching) {
    return <Loading />;
  }

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setRender(!render);
  };

  const handleSearch = async () => {
    const params = new URLSearchParams();
    params.set("fjaleKyce", state.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setRender(!render);
  };
  //#endregion

  return (
    <div className="bg-white">
      <div className="bg-zinc-200 py-2">
        <div className="mx-auto max-w-screen-2xl px-2 sm:px-0">
          <div className="flex justify-start pt-2">
            <p className="text-sm text-gray-500">Automjete</p>
            <p className="text-sm text-gray-500 px-2">|</p>
            <p className="text-sm text-gray-500"> Koncensionare makinash </p>
          </div>
          <h3 className="text-5xl ui-serif sm:my-4">Koncensionare makinash</h3>
          <div className="flex my-8">
            <div className="">
              <input
                autoComplete="off"
                onChange={(e) => setState(e.target.value)}
                value={state}
                placeholder="Krko sipas emrit"
                type="text"
                name="fjaleKyce"
                className=" w-full px-3 py-2 border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-gray-500 invalid:border-red-500 invalid:text-red-600"
              />
            </div>
            <button
              onClick={handleSearch}
              type="button"
              className="ml-4 uppercase inline-flex justify-center p-2 rounded-md bg-neutral-800 px-3  text-sm font-semibold text-white"
            >
              Kerko
            </button>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-screen-2xl px-2 py-5 2xl:px-0 sm:py-5">
        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {data?.data.map((agency: any) => (
            <li key={agency.id} className="text-center border">
              <img
                alt=""
                src={agency.image}
                className="mx-auto w-full h-52 object-contain bg-gray-100 p-5"
                onClick={() => navigate(`/automjete/koncesionar/${agency.id}/${beautifyUrl(agency.name)}`)}
              />
              <h3
                className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900 cursor-pointer"
                onClick={() => navigate(`/automjete/koncesionar/${agency.id}/${beautifyUrl(agency.name)}`)}
              >
                {agency.name}
              </h3>
              <p className="text-sm/6 text-gray-600">
                Agjensi pronash ({agency.properties_count} listime)
              </p>
              <p className="text-sm/6 text-gray-600 px-5 line-clamp-2">
                {agency.about}
              </p>
              <ul className="mt-3 flex justify-center gap-x-6 pb-5">
                <li>
                  <a
                    href="linkedin.com"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">X</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="size-5"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href={agency.linkedinUrl}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="size-5"
                    >
                      <path
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
          ))}
        </ul>
        <div className="mt-5">
          <Pagination
            currentPage={data?.current_page ?? 1}
            from={data?.from ?? 0}
            to={data?.to ?? 0}
            total={data?.total ?? 0}
            last={data?.last_page ?? 0}
            onPageClick={handlePageClick}
          />
        </div>
      </div>
    </div>
  );
}

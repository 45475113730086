import React from "react";
import AccountSidebar from "../AccountSidebar";
import { useSelector } from "react-redux";
import NotificationAlert from "../../../general/NotificationAlert";
import { LayoutSelectorProps, NavigationItem } from "../../../types/General";

import {
  ChartPieIcon,
  HeartIcon,
  HomeIcon,
  HomeModernIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const FrontLayout: React.FC<LayoutSelectorProps> = ({ children }) => {
  //#region constants
  let navigation: NavigationItem[] = [
    {
      id: 1,
      name: "Llogaria kryesore", 
      href: "/account/main/dashboard",
      icon: HomeIcon,
      role: [],
    },
    {
      id: 2,
      name: "Dashboard",
      href: "/account/properties/dashboard",
      icon: HomeModernIcon,
      role: [],
    },
    {
      id: 3,
      name: "Profili",
      href: "/account/properties/agency/profile",
      icon: UserIcon,
      role: [],
    },
    {
      id: 4,
      name: "Shto prone",
      href: "/account/properties/create",
      icon: PlusCircleIcon,
      role: [],
    },
    {
      id: 5,
      name: "Lista e pronave",
      href: "/account/properties/property-list",
      icon: ListBulletIcon,
      role: [],
    },
    {
      id: 6,
      name: "Pronat e ruajtura",
      href: "/account/properties/saved",
      icon: HeartIcon,
      role: [],
    },
    {
      id: 7,
      name: "Agjentet",
      href: "#",
      icon: UsersIcon,
      role: ["propertyAgency"],
    },
    { id: 8, name: "Reports", href: "#", icon: ChartPieIcon, role: [] },
  ];

  const generalData = useSelector((state: any) => state.main.generalData);

  if (generalData?.roles) {
    if (!generalData.roles.includes("propertyAgency")) {
      navigation = navigation.filter(
        (item) => !item?.role?.includes("propertyAgency")
      );
    }
  }
  //#endregion

  return (
    <div>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default FrontLayout;

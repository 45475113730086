import { useEffect, useState } from "react";
import { useCreatePropertyMutation } from "../../../store/account/properties/accountPropertiesStore";
import { useDispatch } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import FileUpload from "../../../components/general/FileUpload";
import {
  Cities,
  CreatePropertyStatuses,
  PropertyBathrooms,
  PropertyFurnitures,
  PropertyRooms,
  PropertyTypes,
} from "../../../services/constants";
import { compressImages, isNotNullOrUndefined } from "../../../services/helpers";
import { Zones } from "../../../services/zones";
import { useLocation, useNavigate } from "react-router-dom";
import EFInput from "../../../components/general/EFInput";
import CkEdit from "../../../components/general/CkEdit";
import SelectBox from "../../../components/general/SelectBox";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Krijo prone", href: "#", current: true },
];

export default function CreateProperty() {
  //#region costants
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let actAs = 3;
  if (location.pathname.startsWith("/account/properties/agency")) actAs = 1;
  if (location.pathname.startsWith("/account/properties/agent")) actAs = 2;
  //#endregion

  //#region rtk
  const [createProperty] = useCreatePropertyMutation();
  //#endregion

  //#region states
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>();
  const [cityZones, setCityZones] = useState<any>();
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    let value = e;
    if (
      key === "city_id" ||
      key === "status_id" ||
      key === "type_id" ||
      key === "zone_id" ||
      key === "bathrooms" ||
      key === "rooms" ||
      key === "furniture_id"
    ) {
      value = e;
    } else {
      value = e.target.value;
    }

    //Filter zones based on city
    if (key === "city_id")
      setCityZones(Zones.filter((x) => x.city_id === value));

    //Remove from state keys that are not related with type, update state
    if (key === "type_id" && (value !== 1 || value !== 4)) {
      const updatedFormData = { ...formData };
      delete updatedFormData["rooms"];
      delete updatedFormData["furniture_id"];
      delete updatedFormData["bathrooms"];
      setFormData({
        ...updatedFormData,
        [key]: value,
      });
      return;
    }

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeEditor = (event: any, editor: any) => {
    setFormData({
      ...formData,
      description: editor.getData(),
    });
  };

  //Submit property
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    formData.actAs = actAs;
    const response: any = await createProperty(formData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U krijua me suksess!", visible: true })
      );
      if (actAs === 1) navigate("/account/properties/agency/property-list");
      if (actAs === 2) navigate("/account/properties/agent/property-list");
      if (actAs === 3) navigate("/account/properties/person/property-list");
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };


  const handleFilesChange = async (e: any) => {
    const imagesFull = await compressImages(e);
    const imagesThumbnail = await compressImages(e, 650);
    setFormData({
      ...formData,
      imagesFull: imagesFull,
      imagesThumbnail: imagesThumbnail
    });
  };
  //#endregion

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-12">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <EFInput
              onChange={handleChange}
              label="Titulli i prones"
              name="name"
              validations={errors}
            />
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "status_id")}
                items={CreatePropertyStatuses}
                name="status_id"
                label="Statusi"
                validations={errors}
              />
            </div>
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "type_id")}
                items={PropertyTypes}
                name="type_id"
                label="Lloji i prones"
                validations={errors}
              />
            </div>
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "city_id")}
                items={Cities}
                name="city_id"
                defaultValue={formData?.city_id}
                label="Zgjidh qytetin"
                validations={errors}
              />
            </div>
            {isNotNullOrUndefined(formData) && formData.city_id && (
              <div>
                <SelectBox
                  onSelectChange={(e: any) => handleChange(e, "zone_id")}
                  items={cityZones}
                  defaultValue={formData?.zone_id}
                  label="Zgjidh zonen"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  onSelectChange={(e: any) => handleChange(e, "rooms")}
                  items={PropertyRooms}
                  defaultValue={formData?.rooms}
                  name="rooms"
                  label="Dhoma"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  onSelectChange={(e: any) => handleChange(e, "bathrooms")}
                  defaultValue={formData?.bathrooms}
                  items={PropertyBathrooms}
                  name="bathrooms"
                  label="Tualete"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  onSelectChange={(e: any) => handleChange(e, "furniture_id")}
                  defaultValue={formData?.furniture_id}
                  items={PropertyFurnitures}
                  name="furniture_id"
                  label="Mobilimi"
                  validations={errors}
                />
              </div>
            )}
            <EFInput
              onChange={handleChange}
              label="Adresa"
              name="address"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              label="Cmimi"
              defaultValue={formData?.price}
              name="price"
              placeholder="Vendosni cmimin"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              label="Siperfaqja"
              defaultValue={formData?.surface}
              name="surface"
              placeholder="Siperfaqja"
              validations={errors}
            />
            {formData.type_id !== 2 && (
              <EFInput
                onChange={handleChange}
                type="number"
                label="Kati"
                defaultValue={formData?.floor}
                name="floor"
                placeholder="Kati"
                validations={errors}
              />
            )}
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <CkEdit
                onChange={handleChangeEditor}
                label="Pershkrimi"
                defaultValue={formData?.desc}
                name="description"
                validations={errors}
              />
            </div>
          </div>
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
            Ngarko fotot e prones
          </label>
          <div className="my-2 col-span-full">
            <FileUpload
              onFilesChange={(e) => handleFilesChange(e)}
              cols={3}
              validations={errors}
            />
          </div>
          <div className="grid grid-col-3"></div>
        </div>
      </div>
      <button
        type="button"
        className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
        onClick={handleSubmit}
      >
        Save
      </button>
    </>
  );
}

import {
  beautifyUrl,
  isLogedIn,
  isNotNullOrUndefined,
  price,
  removeCookie,
} from "../../../../src/services/helpers";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  MapPinIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import "swiper/css";
import "swiper/css/pagination";
import "../carousel/styles.css";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { findInObjects } from "../../../../src/services/helpers";
import {
  Cities,
  PropertyFurnitures,
  PropertyRooms,
} from "../../../services/constants";
import { useSavePropertyMutation } from "../../../store/properties/PropertiesStore";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/main/MainSlice";
import PropertyContactModal from "../../general/contacts/PropertyContactModal";

export default function PropertyMainCard(props: any) {
  //#region constants
  const dispatch = useDispatch();
  const [saveProperty] = useSavePropertyMutation();
  const [property, setProperty] = useState(props.property);
  //#endregion

  //#region refs
  const refContactModal = useRef<any>(null);
  //#endregion

  //#region functions
  const handleSaveProperty = async () => {
    try {
      if (isLogedIn()) {
        const result: any = await saveProperty({ ids: [property.id] });
        if (result?.data?.status) {
          const updatedProperty = { ...property };
          updatedProperty.liked = !updatedProperty.liked;
          setProperty(updatedProperty);
          dispatch(
            showNotification({ msg: result.data.message, visible: true })
          );
        }
        if (result?.error?.status === 401) {
          dispatch(
            showNotification({ msg: "Please login first!", visible: true })
          );
          removeCookie("authToken");
          window.location.reload();
        }
      } else {
        dispatch(
          showNotification({ msg: "Please login first!", visible: true })
        );
      }
    } catch (error) {}
  };
  const handleContactBtn = () => {
    if (refContactModal.current) {
      if (property?.agent)
        return refContactModal.current?.handleContactPopup(
          property.agent.user_id,
          "PROPERTIES"
        );
      if (property?.agency)
        return refContactModal.current?.handleContactPopup(
          property.agency.user_id,
          "PROPERTIES"
        );
      if (property?.person)
        return refContactModal.current?.handleContactPopup(
          property.person.user_id,
          "PROPERTIES"
        );
    }
  };
  //#endregion

  return (
    <>
      <PropertyContactModal
        forwardedRef={refContactModal}
        description={`Pershendetje interesohem ne lidhje me pronen <a href="/prona/${property.id}" class="ck-link_selected"><strong>${property.name}</strong></a>!`}
      />
      <div className="group relative flex flex-col overflow-hidden border border-gray-200 dark:border-neutral-800 bg-white dark:bg-transparent dark:text-white">
        <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none relative">
          <Swiper
            slidesPerView={1}
            modules={[Navigation]}
            loop={false}
            className="swiper-card-img-swiper"
            navigation={{
              nextEl: ".swiper-card-img-next",
              prevEl: ".swiper-card-img-prev",
            }}
          >
            <div className="swiper-card-img">
              <div className="swiper-card-img-prev swiper-button-prevs"></div>
              <div className="swiper-card-img-next swiper-button-nexts"></div>
            </div>
            {property?.images_urls
              ?.slice(0, 5)
              .map((image: any, index: any) => (
                <SwiperSlide key={index}>
                  {index === 4 ? (
                    <div>
                      <img
                        src={image.url}
                        alt={image.name}
                        className="h-64 sm:h-80 w-full object-cover object-center"
                      />
                      <div className="absolute w-full h-80 text-center z-10 pt-36 top-0">
                        <Link
                          to="#"
                          className="text-white rounded-xl bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
                        >
                          Shiko {property.images_urls.length} fotot
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={image.url}
                      alt={image.name}
                      className="h-64 sm:h-80 w-full object-cover object-center"
                    />
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="absolute right-3 top-3 z-10 flex">
            <HeartIcon
              title={property.liked ? "E preferuar" : "Shto tek te preferuarat"}
              className={`h-8 w-8 text-white ${
                property.liked ? "fill-white" : ""
              }`}
              onClick={handleSaveProperty}
            />
          </div>
          <div className="absolute left-3 top-3">
            <div className="flex">
              <Link
                to="#"
                className="text-white z-10 rounded-md bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
              >
                {property.status === 1 ? "Shitet" : "Qera"}
              </Link>
              {isNotNullOrUndefined(property.furniture) && (
                <Link
                  to="#"
                  className="ml-1 text-white z-10 rounded-md bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
                >
                  {findInObjects(
                    PropertyFurnitures,
                    "id",
                    "name",
                    property.furniture
                  )}
                </Link>
              )}
            </div>
          </div>
          {property.is_premium && (
            <div className="absolute left-3 bottom-3 z-10">
              <StarIcon
                title="Premium post"
                className={`h-8 w-8 text-white`}
                onClick={handleSaveProperty}
              />
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col space-y-1 p-4 bg-gray-50 dark:bg-transparent">
          <div className="flex justify-between">
            <div>{price(property.price)}</div>
            <div className="flex cursor-pointer" onClick={handleContactBtn}>
              <ChatBubbleBottomCenterTextIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </div>
          <h3 className="truncate text-md font-medium text-gray-900 dark:text-white hover:text-red-700 dark:hover:text-red-600">
            <Link to={`/prona/${property.id}/${beautifyUrl(property.name)}`}>
              {property.name}-{property.id}
            </Link>
          </h3>
          <p className="text-xs mt-0 dark:text-gray-400 flex">
            <MapPinIcon className="h-4 pr-1" />
            {property.address},{" "}
            {findInObjects(Cities, "id", "name", property.city)}
          </p>
          <p
            className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2"
            dangerouslySetInnerHTML={{ __html: property?.desc }}
          ></p>
          <div className="flex justify-start text-gray-500 dark:text-white">
            <p className="text-sm">
              {" "}
              {findInObjects(PropertyRooms, "id", "name", property.rooms)}
            </p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">WC {property.baths}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">Siperfaqa {property.surface} m&sup2;</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">Kati {property.floor}</p>
          </div>
        </div>
        <hr className="dark:border-neutral-800" />
        <div className="flex justify-between p-4 bg-gray-100 dark:bg-transparent">
          {property.agent && !property?.agent?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {property?.agent?.name}
            </p>
          )}
          {property.agency && !property?.agency?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {property?.agent?.name}
            </p>
          )}
          {property.person && !property?.person?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {property?.person?.name}
            </p>
          )}
          {property?.person?.image && (
            <div className="text-right float-right right-0">
              <div className="flex">
                <img
                  src={property.person.image}
                  alt="test"
                  className="h-10 object-contain object-left rounded-md"
                />
                <p className="text-sm text-gray-500 my-auto pl-2">
                  {property?.person?.name}
                </p>
              </div>
            </div>
          )}
          {property?.agency?.image && (
            <div className="flex justify-end">
              <img
                src={property.agency.image}
                alt="test"
                className="h-10 object-contain object-left rounded dark:invert"
              />
            </div>
          )}
          {property?.agent?.image && (
            <div className="flex justify-end">
              <p className="text-sm text-gray-500 my-auto pr-2">
                {property.agent?.name}
              </p>
              <img
                alt="Agent profile img"
                src={property.agent?.image}
                className="h-10 object-contain object-left rounded-md"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import SingleImageSlider from "../../../../components/front/slider/SingleImageSlider";
import { findInObjects, mapAddress } from "../../../../services/helpers";
import { Cities } from "../../../../services/constants";

export default function ImagesModal(props: any) {
  const { propertyData } = props;

  //Constants
  const { open, setOpen, images } = props;

  //states
  const [tab, setTab] = useState<string>("grid");

  const ImageGrid = () => {
    const calculateColSpan = (index: any) => {
      const positionInRow = index % 6;
      const imagesLeft = images.length - index;

      if (positionInRow === 0) return 6;
      if (positionInRow === 1 || positionInRow === 2) {
        return imagesLeft === 1 ? 6 : 3;
      }
      if (positionInRow === 3 || positionInRow === 4 || positionInRow === 5) {
        if (imagesLeft === 1) return 6;
        if (imagesLeft > 1 && imagesLeft < 3) return 3;
        return 2;
      }
    };

    return (
      <div className="grid grid-cols-6 gap-1 sm:gap-4">
        {images.map((image: any, index: number) => {
          const colSpan = calculateColSpan(index);
          return (
            <div
              key={index}
              className={`col-span-${tab === "list" ? 6 : colSpan}`}
            >
              <img
                className="aspect-[16/10] w-full object-cover"
                src={image.url}
                alt="img"
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={`hidden col-span-1`}></div>
      <div className={`hidden col-span-2`}></div>
      <div className={`hidden col-span-3`}></div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          onClose={() => setOpen(false)}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden w-full">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 flex w-full max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-full bg-white dark:bg-zinc-900">
                    <div className="flex h-full flex-col overflow-y-auto bg-white dark:bg-zinc-900 shadow-xl">
                      <div className="h-full pt-14 w-full">
                        <div className="py-3 fixed top-0 w-full bg-white dark:bg-zinc-900 z-10">
                          <div className="flex items-start justify-between px-3 items-center">
                            <div>
                              <button
                                type="button"
                                className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${
                                  tab === "grid"
                                    ? "text-white bg-gray-900 dark:text-zinc-800 dark:bg-white"
                                    : "dark:text-white"
                                }`}
                                onClick={() => setTab("grid")}
                              >
                                Foto grid
                              </button>
                              <button
                                type="button"
                                className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${
                                  tab === "list"
                                    ? "text-white bg-gray-900 dark:text-zinc-800 dark:bg-white"
                                    : "dark:text-white"
                                }`}
                                onClick={() => setTab("list")}
                              >
                                Foto
                              </button>
                              <button
                                type="button"
                                className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${
                                  tab === "slider"
                                    ? "text-white bg-gray-900 dark:text-zinc-800 dark:bg-white"
                                    : "dark:text-white"
                                }`}
                                onClick={() => setTab("slider")}
                              >
                                Slider
                              </button>
                              <button
                                type="button"
                                className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${
                                  tab === "map"
                                    ? "text-white bg-gray-900 dark:text-zinc-800 dark:bg-white"
                                    : "dark:text-white"
                                }`}
                                onClick={() => setTab("map")}
                              >
                                Harta
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() => setOpen(false)}
                                type="button"
                                className="mx-1 inline-flex items-center text-gray-600 shadow-sm"
                              >
                                <XCircleIcon
                                  className="h-8 w-8"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {(tab === "grid" || tab === "list") && <ImageGrid />}
                        {tab === "map" && (
                          <iframe
                            title="map"
                            width="100%"
                            style={{ height: "calc(100vh - 56px)" }}
                            src={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${mapAddress(
                              propertyData.address,
                              findInObjects(
                                Cities,
                                "id",
                                "name",
                                propertyData.city_id
                              )
                            )}&z=14&output=embed`}
                          ></iframe>
                        )}
                        {tab === "slider" && (
                          <div className="w-full h-full bg-neutral-800 relative overscroll-none">
                            <SingleImageSlider
                              images={images}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

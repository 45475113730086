import { price } from "../../../../src/services/helpers";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import "swiper/css";
import "swiper/css/pagination";
import "../../front/carousel/styles.css";
import { Link } from "react-router-dom";

export default function CarMainCard({ carData }: { carData: any }) {
  return (
    <>
      <div className="group relative flex flex-col overflow-hidden border border-gray-200 dark:border-neutral-800 bg-white dark:bg-transparent dark:text-white">
        <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none relative">
          <Swiper
            slidesPerView={1}
            modules={[Navigation]}
            loop={false}
            className="swiper-card-img-swiper"
            navigation={{
              nextEl: ".swiper-card-img-next",
              prevEl: ".swiper-card-img-prev",
            }}
          >
            <div className="swiper-card-img">
              <div className="swiper-card-img-prev swiper-button-prevs"></div>
              <div className="swiper-card-img-next swiper-button-nexts"></div>
            </div>
            {carData?.images?.map((img: any, index: any) => (
              <SwiperSlide key={index}>
                <img
                  src={URL.createObjectURL(img)}
                  alt={img?.name}
                  className="h-64 sm:h-80 w-full object-cover object-center"
                />
              </SwiperSlide>
            ))}
            {!carData?.images && (
              <SwiperSlide>
                <img
                  src="/demo/car-1.jpg"
                  alt="demo"
                  className="h-64 sm:h-80 w-full object-cover object-center"
                />
              </SwiperSlide>
            )}
          </Swiper>
          <div className="absolute right-3 top-3 z-10 flex">
            <HeartIcon
              title={carData.liked ? "E preferuar" : "Shto tek te preferuarat"}
              className={`h-8 w-8 text-white ${
                carData.liked ? "fill-white" : ""
              }`}
            />
          </div>
          <div className="absolute left-3 top-3">
            <div className="flex">
              <Link
                to="#"
                className="text-white z-10 rounded-md bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
              >
                {carData.status_id === 1 ? "Shitet" : "Qera"}
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 bg-white/50 z-10 px-3 py-1">
            <img
              max-width={70}
              src="/logo192.png"
              alt="test"
              className="h-10 object-contain object-left rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col space-y-1 p-4 bg-gray-50 dark:bg-transparent">
          <div className="flex justify-between">
            <div>{carData.price ? price(carData.price) : "Cmimi"}</div>
            <div className="flex cursor-pointer">
              <ChatBubbleBottomCenterTextIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </div>
          <h3 className="truncate text-md font-medium text-gray-900 dark:text-white hover:text-red-700 dark:hover:text-red-600">
            {carData.make ?? "Marka"} {carData.model ?? "Modeli"}{" "}
            {carData.engine ?? "Motorri"} {carData.fuel ?? "Karburanti"}{" "}
            {carData.potenca ?? "Fuqia motorrike "}HP
          </h3>
          <p
            className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2"
            dangerouslySetInnerHTML={{ __html: carData?.description }}
          ></p>
          <div className="flex justify-start text-gray-800 dark:text-white">
            <p className="text-sm">{carData.year ?? "Viti i prodhimit"}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{carData.fuel ?? "Karburanti"} </p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{carData.milage ?? "Kilometra"} </p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{carData.cambio ?? "Kambio"}</p>
          </div>
        </div>
      </div>
    </>
  );
}

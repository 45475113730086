import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  //#region constants
  const generalData = useSelector((state: any) => state.main.generalData);

  const navigate = useNavigate();
  const propertyAgency = generalData?.roles?.includes("propertyAgency");
  const propertyAgent = generalData?.roles?.includes("propertyAgent");
  const propertyIndivid = generalData?.roles?.includes("propertyIndivid");
  const carAgency = generalData?.roles?.includes("carAgency");
  const carIndivid = generalData?.roles?.includes("carIndivid");
  const jobAgency = generalData?.roles?.includes("jobAgency");
  const jobIndivid = generalData?.roles?.includes("jobIndivid");
  //#endregion

  return (
    <>
      <div className="relative isolate overflow-hidden max-w-full">
        <p>
          Ne aplikacionin tone ne ofrojme sherbime ne kategorite e meposhtme.
        </p>
        <p>
          Plotesoni profilet perkatese ne menyre qe te aksesoni te gjitha
          opsionet qe ofron secila kategori.
        </p>

        <div className="bg-neutral-800 w-full text-white p-2 mt-4">
          Seksioni i pronave
        </div>
        <div className="mt-4">
          <dl className="mx-auto grid grid-cols-1 sm:grid-cols-3 lg:px-2 xl:px-0 gap-2">
            <div
              onClick={() =>
                propertyAgency
                  ? navigate("/account/properties/agency/create-property")
                  : navigate("/account/properties/agency/profile")
              }
              className={classNames(
                propertyAgency ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${propertyAgency ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {propertyAgency ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Agjesi pronash
              </dd>
              <p>Ky seksion eshte per agjensite e pronave.</p>
            </div>
            <div
              onClick={() => navigate("/account/properties/agent/profile")}
              className={classNames(
                propertyAgent ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${propertyAgent ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {propertyAgent ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Agjent pronash
              </dd>
              <p>
                Ky seksion eshte per agjentet e lire te pronave qe nuk i
                perkasin nje agjensie.
              </p>
            </div>
            <div
              onClick={() => navigate("/account/properties/person/profile")}
              className={classNames(
                propertyIndivid ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${propertyIndivid ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {propertyIndivid ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Individ
              </dd>
              <p>Ky seksion eshte per individet pronaret e pronave.</p>
            </div>
          </dl>
        </div>
        <div className="bg-neutral-800 w-full text-white p-2 mt-4">
          Seksioni i makinave
        </div>
        <div className="mt-4">
          <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:px-2 xl:px-0 gap-2">
            <div
              onClick={() => navigate("/account/cars/agency/profile")}
              className={classNames(
                carAgency ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${carAgency ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {carAgency ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Koncesionar makinash
              </dd>
              <p>Ky seksion eshte per agjensite koncesionaret e makinave</p>
            </div>
            <div
              onClick={() => navigate("/account/cars/person/profile")}
              className={classNames(
                carIndivid ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${carIndivid ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {carIndivid ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Individ ose pronar i makines
              </dd>
              <p>Ky seksion eshte per individet pronaret e makinave</p>
            </div>
          </dl>
        </div>
        <div className="bg-neutral-800 w-full text-white p-2 mt-4">
          Seksioni i punesimit
        </div>
        <div className="mt-4">
          <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:px-2 xl:px-0 gap-2">
            <div
              onClick={() => navigate("/account/properties/agency/profile")}
              className={classNames(
                jobAgency ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${jobAgency ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {jobAgency ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Punedhenes
              </dd>
              <p>Ky seksion eshte per kompanite</p>
            </div>
            <div
              onClick={() => navigate("/account/properties/agency/profile")}
              className={classNames(
                jobIndivid ? "border-neutral-800 dark:border-zinc-400" : "",
                "border dark:border-zinc-700 cursor-pointer flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 px-6 py-6"
              )}
            >
              <dt
                className={`text-sm font-medium leading-6 text-gray-500 ${jobIndivid ? "bg-green-800 text-white px-2 rounded-sm" : ""
                  }`}
              >
                {jobIndivid ? "Active" : "Inactive"}
              </dt>
              <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
                Punekerkues
              </dd>
              <p>Ky seksion eshte per individet, punekerkuesit</p>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

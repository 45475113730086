import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { removeCookie } from "../../../services/helpers";
import { useLogoutMutation } from "../../../store/auth/AuthStore";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";

import AccountHeader from "./AccountHeader";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function AccountSidebar({ navigation }: any) {
  //#region costants
  const navigate = useNavigate();
  //#endregion

  //#region states
  const [sidebarOpen, setSidebarOpen] = useState(false);
  //#endregion

  //#region rtk
  const [logout] = useLogoutMutation();
  //#endregion

  //#region functions
  const handleLogout = async () => {
    try {
      const result: any = await logout({});
      if (result.data.status === 200) {
        removeCookie("authToken");
        window.location.href = "/";
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };

  const Logout = () => {
    return (
      <li className="mt-auto">
        <Link
          to="#"
          onClick={handleLogout}
          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-neutral-800 hover:text-white"
        >
          <ArrowUpTrayIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
          Dil
        </Link>
      </li>
    );
  };

  const [openMenu, setOpenMenu] = useState<string | null>(null); // Track open submenu

  const handleMenuClick = (itemName: string) => {
    setOpenMenu(openMenu === itemName ? null : itemName); // Toggle submenu
  };
  //#endregion

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-zinc-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-900 px-6 pb-4 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    className="h-10 w-auto"
                    src="/logo-white.png"
                    alt="Your Company"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map((item: any) => (
                          <li key={item.name}>
                            {item.children ? (
                              <div>
                                <span
                                  onClick={() => handleMenuClick(item.name)} // Toggle submenu visibility
                                  className={classNames(
                                    item.current
                                      ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                      : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer"
                                  )}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className="h-6 w-6 shrink-0"
                                  />
                                  {item.name}
                                </span>

                                {/* Dropdown Menu (Children) */}
                                {openMenu === item.name && (
                                  <ul className=" space-y-1 mt-2">
                                    {item.children.map((child: any) => (
                                      <li
                                        key={child.name}
                                        onClick={() => navigate(child.href)}
                                        className="cursor-pointer"
                                      >
                                        <span
                                          className={classNames(
                                            child.current
                                              ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                              : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                          )}
                                        >
                                          <child.icon
                                            aria-hidden="true"
                                            className="h-5 w-5 shrink-0"
                                          />
                                          {child.name}
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            ) : (
                              // Regular item (without children)
                              <span
                                onClick={() => navigate(item.href)}
                                className={classNames(
                                  item.current
                                    ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                    : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer"
                                )}
                              >
                                {item.icon && (
                                  <item.icon
                                    aria-hidden="true"
                                    className="h-6 w-6 shrink-0"
                                  />
                                )}
                                {item.name}
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                    <Logout />
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex h-16 shrink-0 items-center px-6 bg-neutral-800">
            <img
              className="h-10 w-auto"
              src="/logo-white.png"
              alt="Your Company"
            />
          </div>
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-900 px-6 pb-4 pt-2 border-r border-neutral-800">
            {/* <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item: any) => (
                      <li
                        key={item.name}
                        onClick={() => navigate(item.href)}
                        className="cursor-pointer"
                      >
                        <span
                          className={classNames(
                            item.current
                              ? "bg-neutral-800 text-white border-red-700 border-l-2"
                              : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className="h-6 w-6 shrink-0"
                          />
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
                <Logout />
              </ul>
            </nav> */}
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1 select-none">
                    {navigation.map((item: any) => (
                      <li key={item.name}>
                        {item.children ? (
                          <div>
                            <span
                              onClick={() => handleMenuClick(item.name)} // Toggle submenu visibility
                              className={classNames(
                                item.current
                                  ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                  : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0"
                              />
                              {item.name}
                            </span>

                            {/* Dropdown Menu (Children) */}
                            {(openMenu === item.name ||
                              window.location.pathname.includes(item.href)) && (
                              <ul className="space-y-1 mt-2">
                                {item.children.map((child: any) => (
                                  <li
                                    key={child.name}
                                    onClick={() => navigate(child.href)}
                                    className="cursor-pointer"
                                  >
                                    <span
                                      className={classNames(
                                        child.current
                                          ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                          : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                      )}
                                    >
                                      {child.icon && (
                                        <child.icon
                                          aria-hidden="true"
                                          className="h-5 w-5 shrink-0"
                                        />
                                      )}
                                      {child.name}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          // Regular item (without children)
                          <span
                            onClick={() => navigate(item.href)}
                            className={classNames(
                              item.current
                                ? "bg-neutral-800 text-white border-red-700 border-l-2"
                                : "text-gray-400 hover:bg-neutral-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer"
                            )}
                          >
                            {item.icon && (
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0"
                              />
                            )}
                            {item.name}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <Logout />
              </ul>
            </nav>
          </div>
        </div>
        {/* Header row */}
        <AccountHeader handleSidebar={setSidebarOpen} />
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleSelect({
  items,
  label,
  onSelectChange,
  name,
  className,
  defaultValue = 0,
  validations,
  isFetching,
  required,
}: {
  items: any;
  label: string;
  onSelectChange: any;
  className?: string;
  defaultValue?: number;
  isFetching?: boolean;
  validations?: any;
  name?: string;
  required?: boolean;
}) {
  const dynamicKey = name;
  const [query, setQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const filteredItems =
    query === ""
      ? items
      : items.filter((item: any) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  const handleChange = (value: any) => {
    if (value?.id !== selectedItem?.id && value) {
      setSelectedItem(value);
      onSelectChange(value.id);
      return;
    }
    setSelectedItem(null);
    onSelectChange(null);
  };

  useEffect(() => {
    const selected = items?.find((x: any) => x.id === defaultValue);
    setSelectedItem(selected ?? null);
  }, [defaultValue, items]);

  return (
    <>
      <Combobox
        className={className}
        as="div"
        value={selectedItem}
        onChange={handleChange}
      >
        {label !== "" && (
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
            {label}
            <span className="text-red-600">{required ? "*" : ""}</span>
          </label>
        )}
        <div className="relative">
          <Combobox.Input
            className="w-full h-9 rounded-md bg-white dark:bg-zinc-900 dark:color-white py-1.5 pl-3 pr-12 text-gray-900 dark:text-white shadow-sm border border-slate-300 dark:border-neutral-800 focus:outline-none sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item: any) => item?.name}
          />
          <Combobox.Button className="absolute inset-y-0 bg-gray-100 dark:bg-zinc-900 border border-slate-300 dark:border-neutral-800 right-0 flex items-center rounded-r-md px-2 focus:outline-none focus:border-gray-500">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredItems?.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredItems.map((item: any) => (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active
                        ? "bg-neutral-800 dark:bg-zinc-900 text-white"
                        : "text-gray-900 dark:text-white"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            "ml-3 truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {item.name}
                          <span className="sr-only">
                            {" "}
                            is {item.online ? "online" : "offline"}
                          </span>
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active
                              ? "text-white dark:text-zinc-800"
                              : "text-zinc-600 dark:text-white"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {validations && dynamicKey && validations[dynamicKey] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {validations[dynamicKey]}
        </p>
      )}
    </>
  );
}

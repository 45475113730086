import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../services/apiHeaders";

export const accountCarsApi: any = createApi({
    reducerPath: "account_cars",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "cars/account",
        fetchFn: async (...args) => {
            const response = await fetch(...args);
            if (response.status === 401) {
                window.location.href = "/login";
            }
            if (response.status === 403) {
                alert('Ju nuk keni te drejta per te aksesuar kete url!')
                window.history.back();
            }
            return response;
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),


    endpoints: (builder) => ({
        fetchAgency: builder.query({
            query: (params) => {
                return {
                    url: "agency/profile",
                    method: "GET",
                    params: params
                };
            },
        }),

        saveAgency: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    formData.append(key, body[key]);
                });

                return {
                    url: '/agency/update',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        fetchCars: builder.query({
            query: (params) => {
                return {
                    url: "car-list",
                    method: "GET",
                    params: params,
                };
            },
        }),

        fetchCarSaved: builder.query({
            query: (params) => {
                return {
                    url: "car-saved",
                    method: "GET",
                    params: params,
                };
            },
        }),

        //Create property
        createCar: builder.mutation({
            query: (body) => {
                const formData = new FormData();

                Object.keys(body).forEach((key) => {
                    if (key === 'image' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`image[${index}]`, image); // Use `image[${index}]` as the key
                        });
                    } else if (key === 'imagesThumbnail' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`imagesThumbnail[${index}]`, image);
                        });
                    } else {
                        formData.append(key, body[key]);
                    }
                });

                return {
                    url: '/create-car',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        updateCar: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    if (key === 'imagesFull' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`imagesFull[${index}]`, image);
                        });
                    } else if (key === 'imagesThumbnail' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`imagesThumbnail[${index}]`, image);
                        });
                    } else {
                        formData.append(key, body[key]);
                    }
                });

                return {
                    url: '/update-car',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        updateImagePriority: builder.mutation({
            query: (body) => {

                return {
                    url: '/update-image-priority',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        deleteImage: builder.mutation({
            query: (body) => {

                return {
                    url: '/delete-image',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        //Get data for property update
        fetchCarEdit: builder.query({
            query: (params) => {
                return {
                    url: "/car",
                    method: "GET",
                    params: params
                };
            },
        }),

        //Refresh property
        refreshCar: builder.mutation({
            query: (body) => {
                return {
                    url: '/refresh-car',
                    method: 'POST',
                    body: body,
                };
            },
        }),
        //Delete properties
        deleteCar: builder.mutation({
            query: (body) => {
                return {
                    url: '/delete-car',
                    method: 'POST',
                    body: body,
                };
            },
        }),
        addPremium: builder.mutation({
            query: (body) => {

                return {
                    url: '/add-premium',
                    method: 'POST',
                    body: body,
                };
            },
        }),
        fetchPerson: builder.query({
            query: (params) => {
                return {
                    url: "person/profile",
                    method: "GET",
                    params: params
                };
            },
        }),
        savePerson: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    formData.append(key, body[key]);
                });

                return {
                    url: '/person/update',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),
    }),
});

export const {
    useFetchPropertyAgenciesQuery,
    useFetchAgencyQuery,
    useAddAgentMutation,
    useFetchAgentQuery,
    useFetchCarsQuery,
    useFetchCarSavedQuery,
    useFetchCarEditQuery,
    useAddPremiumMutation,
    useSaveAgencyMutation,
    useSaveAgentMutation,
    useCreateCarMutation,
    useUpdateCarMutation,
    useUpdateImagePriorityMutation,
    useDeleteImageMutation,
    useRefreshPropertyMutation,
    useDeletePropertyMutation,
    useFetchAgencyAgentsQuery,
    useFetchPersonQuery,
    useSavePersonMutation
} = accountCarsApi;

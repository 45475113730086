import {
  beautifyUrl,
  carTitle,
  isLogedIn,
  price,
  removeCookie,
} from "../../../../src/services/helpers";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import "swiper/css";
import "swiper/css/pagination";
import "../carousel/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { findInObjects } from "../../../../src/services/helpers";
import { CarFuels, CarMilages, CarCambios } from "../../../services/constants";
import { useSaveCarMutation } from "../../../store/cars/CarsStore";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/main/MainSlice";
import PropertyContactModal from "../../general/contacts/PropertyContactModal";

export default function CarMainCard(props: any) {
  //#region constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region states
  const [car, setCar] = useState(props.car);
  //#endregion

  //#region refs
  const refContactModal = useRef<any>(null);
  //#endregion

  //#region rtk
  const [AddToFavouriteCar] = useSaveCarMutation();
  //#endregion

  //#region functions
  const handleSaveCar = async () => {
    try {
      if (isLogedIn()) {
        const result: any = await AddToFavouriteCar({ ids: [car.id] });
        if (result?.data?.status) {
          const updatedCar = { ...car };
          updatedCar.liked = !updatedCar.liked;
          setCar(updatedCar);
          dispatch(
            showNotification({ msg: result.data.message, visible: true })
          );
        }
        if (result?.error?.status === 401) {
          dispatch(
            showNotification({ msg: "Please login first!", visible: true })
          );
          removeCookie("authToken");
          window.location.reload();
        }
      } else {
        dispatch(
          showNotification({ msg: "Please login first!", visible: true })
        );
      }
    } catch (error) {}
  };
  const handleContactBtn = () => {
    if (refContactModal.current) {
      if (car?.agency)
        return refContactModal.current?.handleContactPopup(
          car.agency.user_id,
          "CARS"
        );
      if (car?.client)
        return refContactModal.current?.handleContactPopup(
          car.client.user_id,
          "CARS"
        );
    }
  };
  //#endregion

  return (
    <>
      <PropertyContactModal
        forwardedRef={refContactModal}
        description={`Pershendetje interesohem ne lidhje me <a href="/automjete/${
          car.id
        }" class="ck-link_selected"><strong>${carTitle(car)}</strong></a>!`}
      />
      <div className="group relative flex flex-col overflow-hidden border border-gray-200 dark:border-neutral-800 bg-white dark:bg-transparent dark:text-white">
        <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none relative">
          <Swiper
            slidesPerView={1}
            modules={[Navigation]}
            loop={false}
            className="swiper-card-img-swiper"
            navigation={{
              nextEl: ".swiper-card-img-next",
              prevEl: ".swiper-card-img-prev",
            }}
          >
            <div className="swiper-card-img">
              <div className="swiper-card-img-prev swiper-button-prevs"></div>
              <div className="swiper-card-img-next swiper-button-nexts"></div>
            </div>
            {car?.images_urls?.slice(0, 5).map((image: any, index: any) => (
              <SwiperSlide key={index}>
                {index === 4 ? (
                  <div>
                    <img
                      src={image.url}
                      alt={image.name}
                      className="h-64 sm:h-80 w-full object-cover object-center"
                    />
                    <div className="absolute w-full h-80 text-center z-10 pt-36 top-0">
                      <Link
                        to="#"
                        className="text-white rounded-xl bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
                      >
                        Shiko {car.images_urls.length} fotot
                      </Link>
                    </div>
                  </div>
                ) : (
                  <img
                    src={image.url}
                    alt={image.name}
                    className="h-64 sm:h-80 w-full object-cover object-center"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute right-3 top-3 z-10 flex">
            <HeartIcon
              title={car.liked ? "E preferuar" : "Shto tek te preferuarat"}
              className={`h-8 w-8 text-white ${car.liked ? "fill-white" : ""}`}
              onClick={handleSaveCar}
            />
          </div>
          <div className="absolute left-3 top-3">
            <div className="flex">
              <Link
                to="#"
                className="text-white z-10 rounded-md bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
              >
                {car.status_id === 1 ? "Shitet" : "Qera"}
              </Link>
            </div>
          </div>
          {car.agency?.image && (
            <div className="absolute bottom-0 right-0 bg-white/50 z-10 px-3 py-1">
              <img
                onClick={() =>
                  navigate(
                    `/automjete/koncesionar/${car.agency.id}/${beautifyUrl(
                      car.agency.name
                    )}`
                  )
                }
                width={70}
                src={car.agency?.image}
                alt="test"
                className="h-10 object-contain object-left rounded-md"
              />
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col space-y-1 p-4 bg-gray-50 dark:bg-transparent">
          <div className="flex justify-between">
            <div>{price(car.price)}</div>
            <div className="flex cursor-pointer" onClick={handleContactBtn}>
              <ChatBubbleBottomCenterTextIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </div>
          <h3 className="truncate text-md font-medium text-gray-900 dark:text-white hover:text-red-700 dark:hover:text-red-600">
            <Link
              to={`/automjete/${car.id}/${beautifyUrl(
                car.make.name +
                  "-" +
                  car.model.name +
                  "-" +
                  car.engine +
                  "-" +
                  car.potenca +
                  "-HP"
              )}`}
            >
              {car.make.name} {car.model.name} {car.engine} {car.potenca} HP
            </Link>
          </h3>
          <p
            className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2"
            dangerouslySetInnerHTML={{ __html: car?.description }}
          ></p>
          <div className="flex justify-start text-gray-800 dark:text-white">
            <p className="text-sm">{car.year}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">
              {findInObjects(CarFuels, "id", "name", car.fuel_id)}
            </p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">
              {findInObjects(CarMilages, "id", "name", car.milage)}
            </p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">
              {findInObjects(CarCambios, "id", "name", car.cambio_id)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import {
  Listbox,
  Label,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectBox({
  items,
  label,
  onSelectChange,
  name,
  className,
  defaultValue = 0,
  validations,
}: {
  items: any;
  label: string;
  onSelectChange: any;
  className?: string;
  defaultValue?: number;
  isFetching?: boolean;
  validations?: any;
  name?: string;
  required?: boolean;
}) {
  const dynamicKey = name;
  const [selected, setSelected] = useState<any>(null);

  const handleChange = (value: any) => {
    if (value.id !== selected?.id) {
      setSelected(value);
      onSelectChange(value.id);
      return;
    }
    setSelected(null);
    onSelectChange(null);
  };

  useEffect(() => {
    const selected = items?.find((x: any) => x.id === defaultValue);
    setSelected(selected ?? null);
  }, [defaultValue, items]);

  return (
    <>
      <Listbox value={selected} onChange={handleChange} as="div">
        {({ open }) => (
          <>
            {label !== "" && (
              <Label
                className={`block text-sm font-medium leading-6 text-gray-900 dark:text-white ${className}`}
              >
                {label}
              </Label>
            )}
            <div className="relative">
              <ListboxButton className="w-full h-9 text-left rounded-md bg-white dark:bg-zinc-900 dark:color-white py-1.5 pl-3 pr-12 text-gray-900 dark:text-white shadow-sm border border-slate-300 dark:border-neutral-800 focus:outline-none sm:text-sm sm:leading-6">
                <span className="block truncate ">{selected?.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <Transition
                show={open}
                leave="transition ease-in duration-100 border-slate-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  onChange={handleChange}
                  className="dark:bg-neutral-800 absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border-slate-300"
                >
                  {items.map((item: any) => (
                    <ListboxOption
                      key={item?.id}
                      className={({ focus }) =>
                        classNames(
                          focus
                            ? "bg-zinc-900 text-white border-slate-300"
                            : "",
                          !focus ? "text-gray-900 dark:text-zinc-300" : "",
                          "relative cursor-default select-none py-2 pl-8 pr-4"
                        )
                      }
                      value={item}
                    >
                      {({ selected, focus }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {item?.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                focus ? "text-white" : "text-white",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {validations && dynamicKey && validations[dynamicKey] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {validations[dynamicKey]}
        </p>
      )}
    </>
  );
}

export default function ServerComponent() {
  return (
    <div>
      <article>
        <title>Test title</title>
      </article>
      This is rendered on the server!
    </div>
  );
}

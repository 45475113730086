// src/components/LayoutSelector.tsx
import React, { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FrontLayout from "./front/layout/main/MainLayout";
import PropertyLayout from "./front/layout/properties/PropertyLayout";
import MainLayout from "./account/layout/main/MainLayout";
import PropertiesLayout from "./account/layout/properties/PropertiesLayout";
import { getCookie, isNotNullOrUndefined } from "../services/helpers";
import { useFetchGeneralDataQuery } from "../store/main/MainStore";
import { generalData } from "../store/main/MainSlice";
import { useDispatch } from "react-redux";
import PropertiesAgencyLayout from "./account/layout/properties/PropertiesAgencyLayout";
import PropertiesAgentLayout from "./account/layout/properties/PropertiesAgentLayout";
import PropertiesPersonLayout from "./account/layout/properties/PropertiesPersonLayout";
import CarAgencyLayout from "./account/layout/cars/CarAgencyLayout";
import FrontCarsLayout from "./front/layout/cars/CarsLayout";
import SystemLayout from "./account/layout/system/SystemLayout";

const LayoutSelector: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  //#region costants
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region rtk
  const { data } = useFetchGeneralDataQuery({});
  //#endregion

  //#region useEffect
  useEffect(() => {
    const theme = getCookie("theme");
    if (data?.roles) {
      dispatch(generalData(data));
    }
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    }
  }, [data, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  //#endregion

  if (
    location.pathname.startsWith("/account") &&
    !isNotNullOrUndefined(getCookie("authToken"))
  )
    navigate("login");

  //Properties
  if (location.pathname.startsWith("/account/properties/agency"))
    return <PropertiesAgencyLayout>{children}</PropertiesAgencyLayout>;

  if (location.pathname.startsWith("/account/properties/agent"))
    return <PropertiesAgentLayout>{children}</PropertiesAgentLayout>;

  if (location.pathname.startsWith("/account/properties/person"))
    return <PropertiesPersonLayout>{children}</PropertiesPersonLayout>;

  if (location.pathname.startsWith("/account/properties"))
    return <PropertiesLayout>{children}</PropertiesLayout>;

  if (location.pathname.startsWith("/account/cars"))
    return <CarAgencyLayout>{children}</CarAgencyLayout>;

  if (location.pathname.startsWith("/account"))
    return <MainLayout>{children}</MainLayout>;

  if (location.pathname.startsWith("/prona"))
    return <PropertyLayout>{children}</PropertyLayout>;

  if (location.pathname.startsWith("/automjete"))
    return <FrontCarsLayout>{children}</FrontCarsLayout>;

  if (location.pathname.startsWith("/system"))
    return <SystemLayout>{children}</SystemLayout>;

  return <FrontLayout>{children}</FrontLayout>;
};

export default LayoutSelector;

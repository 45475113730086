import { useEffect, useState } from "react";
import {
  useUpdatePropertyMutation,
  useFetchPropertyEditQuery,
  useUpdateImagePriorityMutation,
  useDeleteImageMutation,
  useAddPremiumMutation,
} from "../../../store/account/properties/accountPropertiesStore";
import { useDispatch } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import FileUpload from "../../../components/general/FileUpload";
import {
  Cities,
  CreatePropertyStatuses,
  PropertyBathrooms,
  PropertyFurnitures,
  PropertyPostingZones,
  PropertyRooms,
  PropertyTypes,
} from "../../../services/constants";
import SingleSelect from "../../../components/general/SingleSelect";
import {
  compressImages,
  findInObjects,
  isNotNullOrUndefined,
} from "../../../services/helpers";
import { Zones } from "../../../services/zones";
import { useParams } from "react-router-dom";
import EFInput from "../../../components/general/EFInput";
import CkEdit from "../../../components/general/CkEdit";
import Loading from "../../../components/general/Loading";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectBox from "../../../components/general/SelectBox";
import DateTimePicker from "../../../components/general/DateTimePicker";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Detaje", href: "#", current: true },
];

export default function EditProperty() {
  //#region constants
  const { id } = useParams();
  const dispatch = useDispatch();
  const tomorow = new Date();
  tomorow.setDate(tomorow.getDate() + 1);

  const newPremiumDefault = {
    premium_from: new Date(),
    premium_to: tomorow.toISOString(),
    minTo: tomorow.toISOString(),
    minFrom: new Date(),
  };
  //#endregion

  //#region rtk
  const [updateProperty] = useUpdatePropertyMutation();
  const [updateImagePriority] = useUpdateImagePriorityMutation();
  const [addPremium] = useAddPremiumMutation();
  const [deleteImage] = useDeleteImageMutation();
  const { data, isFetching, refetch } = useFetchPropertyEditQuery({ id: id });
  //#endregion

  //#region states
  const [formData, setFormData] = useState<any>({});
  const [newPremium, setNewPremium] = useState<any>(newPremiumDefault);
  const [errors, setErrors] = useState<any>();
  const [cityZones, setCityZones] = useState<any>();
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    let value = e;
    if (
      key === "city_id" ||
      key === "status_id" ||
      key === "type_id" ||
      key === "zone_id" ||
      key === "bathrooms" ||
      key === "rooms" ||
      key === "furniture_id" ||
      key === "premium_id" ||
      key === "premium_from" ||
      key === "premium_to"
    ) {
      value = e;
    } else {
      value = e.target.value;
    }

    //Filter zones based on city
    if (key === "city_id")
      setCityZones(Zones.filter((x) => x.city_id === value));

    //Remove from state keys that are not related with type, update state
    if (key === "type_id" && (value !== 1 || value !== 4)) {
      const updatedFormData = { ...formData };
      delete updatedFormData["rooms"];
      delete updatedFormData["furniture_id"];
      delete updatedFormData["bathrooms"];
      setFormData({
        ...updatedFormData,
        [key]: value,
      });
      return;
    }

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeEditor = (e: any, editor: any) => {
    if (formData?.id) {
      setFormData({
        ...formData,
        description: editor.getData(),
      });
    }
  };

  const handleFilesChange = async (e: any) => {
    const imagesFull = await compressImages(e);
    const imagesThumbnail = await compressImages(e, 650);
    setFormData({
      ...formData,
      imagesFull: imagesFull,
      imagesThumbnail: imagesThumbnail,
    });
  };

  const handleUpdateSubmit = async (e: any) => {
    e.preventDefault();
    const response: any = await updateProperty(formData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
      refetch();
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleChangeImagePriority = async (id: number) => {
    const params = {
      propertyId: formData.id,
      imageId: id,
    };
    const response: any = await updateImagePriority(params);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
      refetch();
    }
  };

  const handleDeleteImage = async (id: number) => {
    const response: any = await deleteImage({
      propertyId: formData.id,
      imageId: id,
    });
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U fshi me sukses!", visible: true }));
      refetch();
    }
  };

  const onChangeFromDate = (e: any) => {
    const date = new Date(e);
    date.setDate(date.getDate() + 1);
    if (newPremium.premium_to < date.toISOString()) {
      setNewPremium({
        ...newPremium,
        premium_from: e,
        minTo: date.toISOString(),
        premium_to: date.toISOString(),
      });
    } else {
      setNewPremium({
        ...newPremium,
        premium_from: e,
        minTo: date.toISOString(),
      });
    }
  };

  const addNewPremium = async () => {
    const insertData = {
      propertyId: formData.id,
      premium_id: newPremium.premium_id,
      premium_from: newPremium.premium_from,
      premium_to: newPremium.premium_to,
    };

    const response: any = await addPremium(insertData);

    if (response.data?.status) {
      dispatch(showNotification({ msg: response.data.message, visible: true }));
      setNewPremium(newPremiumDefault);
      refetch();
    }

    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  useEffect(() => {
    dispatch(broadcrumb(pages));

    if (isNotNullOrUndefined(data)) {
      let property = {
        id: data.id,
        name: data.name,
        status_id: data.status_id,
        type_id: data.type_id,
        city_id: data.city_id,
        zone_id: data.zone_id,
        rooms: data.rooms,
        bathrooms: data.bathrooms,
        furniture_id: data.furniture_id,
        address: data.address,
        price: data.price,
        surface: data.surface,
        floor: data.floor,
        description: data.description,
        premium_from: data.premium?.from,
        premium_to: data.premium?.to,
      };
      setFormData(property);

      setCityZones(Zones.filter((x) => x.city_id === data.city_id));
    }
  }, [data, dispatch]);

  if (isFetching && !formData?.id) return <Loading />;

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-12">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <EFInput
              onChange={handleChange}
              label="Titulli i prones"
              name="name"
              defaultValue={formData.name}
              validations={errors}
            />
            <div>
              <SelectBox
                className="mt-0"
                onSelectChange={(e: any) => handleChange(e, "status_id")}
                items={CreatePropertyStatuses}
                name="status_id"
                label="Statusi"
                defaultValue={formData.status_id}
                validations={errors}
              />
            </div>
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "type_id")}
                items={PropertyTypes}
                name="type_id"
                label="Lloji i prones"
                defaultValue={formData.type_id}
                validations={errors}
              />
            </div>
            <div>
              <SingleSelect
                className="mt-0"
                onSelectChange={(e: any) => handleChange(e, "city_id")}
                items={Cities}
                name="city_id"
                defaultValue={formData?.city_id}
                label="Zgjidh qytetin"
                validations={errors}
              />
            </div>
            {isNotNullOrUndefined(formData) && formData.city_id && (
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) => handleChange(e, "zone_id")}
                  items={cityZones}
                  defaultValue={formData?.zone_id}
                  label="Zgjidh zonen"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) => handleChange(e, "rooms")}
                  items={PropertyRooms}
                  defaultValue={formData?.rooms}
                  name="rooms"
                  label="Dhoma"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) => handleChange(e, "bathrooms")}
                  defaultValue={formData?.bathrooms}
                  items={PropertyBathrooms}
                  name="bathrooms"
                  label="Tualete"
                  validations={errors}
                />
              </div>
            )}
            {(formData.type_id === 1 || formData.type_id === 4) && (
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) => handleChange(e, "furniture_id")}
                  defaultValue={formData?.furniture_id}
                  items={PropertyFurnitures}
                  name="furniture_id"
                  label="Mobilimi"
                  validations={errors}
                />
              </div>
            )}
            <EFInput
              onChange={handleChange}
              label="Adresa"
              name="address"
              defaultValue={formData.address}
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              label="Cmimi"
              defaultValue={formData?.price}
              name="price"
              placeholder="Vendosni cmimin"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              label="Siperfaqja"
              defaultValue={formData?.surface}
              name="surface"
              placeholder="Siperfaqja"
              validations={errors}
            />
            {formData.type_id !== 2 && (
              <EFInput
                onChange={handleChange}
                type="number"
                label="Kati"
                defaultValue={formData?.floor}
                name="floor"
                placeholder="Kati"
                validations={errors}
              />
            )}
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <CkEdit
                onChange={handleChangeEditor}
                label="Pershkrimi"
                defaultValue={formData?.description}
                name="description"
                validations={errors}
              />
            </div>
          </div>
          <div className="col-span-full">
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-4">
              Fotot e prones
            </label>
            <p>
              Per te percaktuar se cila foto do te shfaqet ne fillim tek prona
              klikoni mbi foto.
            </p>
            <div className="grid grid-cols-4 mt-2 gap-2">
              {data?.images?.map((file: any, index: number) => (
                <div key={index} className={`relative`}>
                  <img
                    onClick={() => handleChangeImagePriority(file.id)}
                    src={file.url}
                    alt={file.name}
                    className={`object-cover w-full aspect-video ${
                      file.priority === 1 ? "border border-zinc-500 p-1" : ""
                    }`}
                  />
                  <button
                    onClick={() => handleDeleteImage(file.id)}
                    className="absolute top-1 right-1 p-1 bg-neutral-800 rounded-full"
                  >
                    <XMarkIcon className="h-6 w-6 text-white" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
            Shto foto te tjera
          </label>
          <div className="my-2 col-span-full">
            <FileUpload
              onFilesChange={(e) => handleFilesChange(e)}
              cols={4}
              validations={errors}
              clearFiles={isFetching}
            />
          </div>
          <div className="grid grid-col-3"></div>
        </div>
      </div>
      <button
        type="button"
        className="mt-2 mb-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
        onClick={handleUpdateSubmit}
      >
        Perditeso
      </button>
      <div className="bg-neutral-800 w-full text-white p-2 mt-4">
        <h3>Konfigurime per postime premium</h3>
      </div>
      <div>
        <div className="grid grid-cols-12 gap-4 mt-4 mb-4">
          <div className="col-span-12">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
              <div>
                <SelectBox
                  className="mt-0"
                  onSelectChange={(e: any) =>
                    setNewPremium({ ...newPremium, premium_id: e })
                  }
                  items={PropertyPostingZones.filter((x) => x.id > 0)}
                  defaultValue={newPremium.premium_id}
                  name="premium_id"
                  label="Zona e postimit"
                  validations={errors}
                />
              </div>
              <DateTimePicker
                showTimeSelect={true}
                minDate={newPremium.minFrom}
                name="premium_from"
                label="Premium nga data"
                defaultValue={newPremium.premium_from}
                onChange={onChangeFromDate}
                validations={errors}
              />
              <DateTimePicker
                minDate={newPremium.minTo}
                name="premium_to"
                label="Premium deri ne daten"
                defaultValue={newPremium.premium_to}
                onChange={(e: any) =>
                  setNewPremium({ ...newPremium, premium_to: e })
                }
                validations={errors}
              />
            </div>
            <button
              type="button"
              className="mt-2 mb-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
              onClick={addNewPremium}
            >
              Shto
            </button>
            <div className="overflow-auto" style={{ minHeight: 100 }}>
              <table className="min-w-full divide-y divide-neutral-300">
                <thead className="bg-neutral-300 dark:bg-neutral-800 dark:text-white sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Premium category
                    </th>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Data e fillimit
                    </th>
                    <th
                      scope="col"
                      className="border-b border-gray-300 py-3.5 pl-3 pr-3 text-left text-sm font-semibold"
                    >
                      Data e mbarimit
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-300 dark:divide-neutral-800 bg-white dark:bg-neutral-800">
                  {data?.premiums.map((item: any) => (
                    <tr
                      key={item.id}
                      className={`even:bg-neutral-100 dark:even:bg-neutral-900`}
                    >
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {findInObjects(
                          PropertyPostingZones,
                          "id",
                          "name",
                          item.premium_id
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {item.from}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {item.to}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

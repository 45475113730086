import { useEffect, useState } from "react";
import { useCreateCarMutation } from "../../../store/account/cars/accountCarsStore";
import { useDispatch } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import FileUpload from "../../../components/general/FileUpload";
import {
  CarCambios,
  CarFuels,
  CarMilages,
  Cities,
  CreatePropertyStatuses,
} from "../../../services/constants";
import SingleSelect from "../../../components/general/SingleSelect";
import { useLocation, useNavigate } from "react-router-dom";
import EFInput from "../../../components/general/EFInput";
import CkEdit from "../../../components/general/CkEdit";
import CarMainCard from "../../../components/account/cards/CarMainCard";
import {
  useFetchCarDataQuery,
  useFetchModelsByMakeQuery,
} from "../../../store/cars/CarsStore";
import SelectBox from "../../../components/general/SelectBox";
import { compressImages } from "../../../services/helpers";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Krijo prone", href: "#", current: true },
];

export default function CreateCar() {
  //#region costants
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let actAs = 2;
  if (location.pathname.startsWith("/account/cars/agency")) actAs = 1;
  //#endregion

  //#region states
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>();
  //#endregion

  //#region rtk
  const [createProperty] = useCreateCarMutation();

  const { data } = useFetchCarDataQuery({ allBrands: true });
  const { data: models } = useFetchModelsByMakeQuery(
    { make_id: formData.make_id, allModeles: true },
    { skip: formData?.make_id ? formData.make_id < 0 : true }
  );
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    const fields = [
      "city_id",
      "status_id",
      "make_id",
      "model_id",
      "fuel_id",
      "cambio_id",
      "milage",
    ];
    let value = e;
    if (fields.includes(key)) {
      value = e;
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeEditor = (event: any, editor: any) => {
    setFormData({
      ...formData,
      description: editor.getData(),
    });
  };

  //Submit property
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    formData.actAs = actAs;
    const response: any = await createProperty(formData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U krijua me suksess!", visible: true })
      );
      if (actAs === 1) navigate("/account/cars/agency/car-list");
      if (actAs === 2) navigate("/account/cars/client/car-list");
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleFilesChange = async (e: any) => {
    const imagesFull = await compressImages(e);
    const imagesThumbnail = await compressImages(e, 650);
    setFormData({
      ...formData,
      imagesFull: imagesFull,
      imagesThumbnail: imagesThumbnail
    });
  };
  //#endregion

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "status_id")}
                items={CreatePropertyStatuses}
                name="status_id"
                label="Statusi"
                validations={errors}
              />
            </div>
            <div>
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "make_id")}
                items={data?.makes}
                name="make_id"
                label="Marka e makines"
                validations={errors}
              />
            </div>
            {formData?.make_id > 0 && (
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "model_id")}
                name="model_id"
                items={models?.models ?? []}
                label="Zgjidh modelin"
                validations={errors}
              />
            )}
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "fuel_id")}
                items={CarFuels}
                label="Karburanti"
                name="fuel_id"
                validations={errors}
              />
            </div>
            <EFInput
              min={1950}
              onChange={handleChange}
              type="number"
              name="year"
              label="Viti"
              placeholder="Viti i prodhimit"
              validations={errors}
            />
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "milage")}
                items={CarMilages}
                label="KM"
                name="milage"
                validations={errors}
              />
            </div>
            <div>
              <SelectBox
                onSelectChange={(e: any) => handleChange(e, "cambio_id")}
                items={CarCambios}
                label="Kambio"
                name="cambio_id"
                validations={errors}
              />
            </div>
            <EFInput
              onChange={handleChange}
              type="number"
              name="engine"
              label="Motori"
              placeholder="2.2"
              step=".1"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              name="potenca"
              label="Kuaj fuqi"
              placeholder="125"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              type="number"
              name="consum"
              label="Konsumi"
              placeholder="6.5"
              step=".1"
              validations={errors}
            />
            <div>
              <SingleSelect
                onSelectChange={(e: any) => handleChange(e, "city_id")}
                items={Cities}
                name="city_id"
                label="Zgjidh qytetin"
                validations={errors}
              />
            </div>
            <EFInput
              onChange={handleChange}
              type="number"
              name="price"
              label="Cmimi"
              placeholder="Vendos cmimin ne euro"
              validations={errors}
            />
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <CkEdit
                onChange={handleChangeEditor}
                label="Pershkrimi"
                name="description"
                validations={errors}
              />
            </div>
          </div>
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
            Ngarko fotot e automjetit
          </label>
          <div className="my-2 col-span-full">
            <FileUpload
              onFilesChange={(e) => handleFilesChange(e)}
              cols={3}
              validations={errors}
            />
          </div>
          <div className="grid grid-col-3"></div>
        </div>
        <div className="col-span-4">
          <span className="text-sm">Kjo eshte se si postimi juaj do te shfaqet new webin tone. Mundojuni ti plotesoni dhe pershtasni sa me mire te dhenat ne menyre qe postimi te dali i rregullt.</span>
          <CarMainCard
            carData={{
              images: formData.image,
              make: data?.makes?.find((x: any) => x.id === formData.make_id)
                ?.name,
              model: models?.models?.find(
                (x: any) => x.id === formData?.model_id
              )?.name,
              price: formData.price,
              year: formData.year,
              status_id: formData.status_id,
              engine: formData.engine,
              fuel: CarFuels.find((x: any) => x.id === formData?.fuel_id)?.name,
              cambio: CarCambios.find((x: any) => x.id === formData?.cambio_id)
                ?.name,
              potenca: formData.potenca,
              milage: CarMilages.find((x: any) => x.id === formData?.milage)
                ?.name,
              description: formData.description,
            }}
          />
        </div>
      </div>
      <button
        type="button"
        className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
        onClick={handleSubmit}
      >
        Save
      </button>
    </>
  );
}

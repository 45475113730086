import NotFound from "../pages/NotFound";
import { Route, Routes } from "react-router-dom";
import AboutUs from "../pages/front/main/About";
import ContactUs from "../pages/front/main/ContactUs";
import CarAgencyDetails from "../pages/front/cars/CarAgencyDetails";
import CarAgencies from "../pages/front/cars/CarAgencies";
import CarDetails from "../pages/front/cars/CarDetails";
import Dashboard from "../pages/system/Dashboard";
import Users from "../pages/system/Users";
import Agencies from "../pages/system/properties/Agencies";
import Agents from "../pages/system/properties/Agents";
import Properties from "../pages/system/properties/Properties";

export default function System() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/users" element={<Users />} />
      <Route path="/properties" element={<Properties />} />
      <Route path="/properties/agencies" element={<Agencies />} />
      <Route path="/properties/agents" element={<Agents />} />
      <Route path="/:id/:slug" element={<CarDetails />} />
      <Route path="/koncesionare" element={<CarAgencies />} />
      <Route path="koncesionar/:id/:slug" element={<CarAgencyDetails />} />
      <Route path="/rreth-nesh" element={<AboutUs />} />
      <Route path="/kontakt" element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

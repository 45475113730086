import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Cities,
  CarStatuses,
  CarFuels,
  CarCambios,
  CarSellerTypes,
} from "../../../services/constants";
import SingleSelect from "../../general/SingleSelect";
import EFInput from "../../general/EFInput";
import EFRadioGroup from "../../general/EFRadioGroup";
import { findInObjects, getUrlParams } from "../../../services/helpers";
import SelectBox from "../../general/SelectBox";
import {
  useFetchCarDataQuery,
  useFetchModelsByMakeQuery,
} from "../../../store/cars/CarsStore";

export default function CarModalFilters({
  onFiltersChange,
  exclude,
}: {
  onFiltersChange: any;
  exclude?: any[];
}) {
  const params = getUrlParams();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(params);

  //#region rtk
  const { data, isFetching } = useFetchCarDataQuery({});
  const { data: models } = useFetchModelsByMakeQuery(
    { make_id: formData.make_id },
    { skip: formData?.make_id ? formData.make_id < 0 : true }
  );
  //#endregion

  const handleChange = (e: any, key: string) => {
    let value = e;
    if (
      key === "minPrice" ||
      key === "maxPrice" ||
      key === "yearFrom" ||
      key === "yearTo"
    )
      value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  //Clear filters by key from state and refilter data
  const clear = (keyToRemove: string) => {
    const updatedFormData = { ...formData };
    delete updatedFormData[keyToRemove];
    if (keyToRemove === "city") delete updatedFormData["zone"];
    setFormData(updatedFormData);
    onFiltersChange(updatedFormData);
  };

  const submitdata = () => {
    onFiltersChange(formData);
    setOpen(false);
  };
  const clearFilters = () => {
    onFiltersChange({});
    setOpen(false);
    setFormData({});
  };

  if (isFetching) return <></>;

  return (
    <>
      <div className="bg-zinc-200 dark:bg-zinc-900">
        <hr className="dark:border-neutral-800" />
        <div className="flex mx-auto max-w-screen-2xl px-2 2xl:px-0 py-2">
          <button
            type="button"
            className="relative text-xs rounded-md white border border-zinc-300 dark:border-zinc-600 dark:text-white flex px-2 py-1 h-7"
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <span className="mt-px capitalize min-w-max">Filtrat</span>
          </button>
          <div className="overflow-x-auto flex ml-2">
            {params.status_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("status_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {parseInt(params.status_id) === 1 ? "Shitet" : "Qera"}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.make_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("make_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    data?.makes,
                    "id",
                    "name",
                    parseInt(params.make_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.model_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("model_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    models?.models,
                    "id",
                    "name",
                    parseInt(params.model_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}

            {params.fuel_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("fuel_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    CarFuels,
                    "id",
                    "name",
                    parseInt(params.fuel_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}

            {params.yearFrom && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("yearFrom")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    Nga {params.yearFrom}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.yearTo && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("yearTo")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    Deri {params.yearTo}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}

            {params.minPrice && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("minPrice")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    €{params.minPrice}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.maxPrice && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("maxPrice")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    €{params.maxPrice}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.cambio_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("cambio_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    CarCambios,
                    "id",
                    "name",
                    parseInt(params.cambio_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.seller_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("seller_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    CarSellerTypes,
                    "id",
                    "name",
                    parseInt(params.seller_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.city_id && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("city_id")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    Cities,
                    "id",
                    "name",
                    parseInt(params.city_id)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {Object.keys(params).length > (params.page ? 1 : 0) && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
              >
                <span className="mt-0.5 capitalize min-w-max">
                  Hiq te gjithe filtrat
                </span>
                <XMarkIcon
                  className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm"
                  onClick={() => clearFilters()}
                />
              </button>
            )}
          </div>
        </div>
        <hr className="dark:border-neutral-800" />
      </div>
      <Dialog
        open={open}
        onClose={setOpen}
        className="relative z-10 overflow-hidden"
      >
        <div className="fixed z-10 inset-0 bg-black opacity-50" />
        <div className="fixed z-10 inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-lg transform transition duration-500 ease-in-out data-[closed]:-translate-x-full translate-x-0 sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white pt-14 shadow-xl">
                  <form className="flex h-full flex-col divide-y divide-zinc-200 dark:divide-zinc-700 bg-white dark:bg-zinc-900 shadow-xl">
                    <div className="px-4 py-4 sm:px-6 bg-zinc-200 w-full dark:bg-zinc-900 dark:text-white">
                      <div className="flex items-center justify-between">
                        <div className="font-semibold leading-6">
                          Filtro automjetet
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="h-0 flex-1 overflow-y-auto pb-8  pt-2">
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <EFRadioGroup
                            items={CarStatuses}
                            onChange={handleChange}
                            name="status_id"
                            selectedItem={parseInt(formData.status_id)}
                            smCols={2}
                          />
                          <SingleSelect
                            className="mt-2"
                            onSelectChange={(e: any) =>
                              handleChange(e, "make_id")
                            }
                            defaultValue={parseInt(formData?.make_id)}
                            items={data?.makes}
                            label="Zgjidh marken"
                          />
                          {formData?.make_id > 0 && (
                            <SingleSelect
                              className="mt-2"
                              onSelectChange={(e: any) =>
                                handleChange(e, "model_id")
                              }
                              defaultValue={parseInt(formData?.model_id)}
                              items={models?.models ?? []}
                              label="Zgjidh modelin"
                            />
                          )}
                          <EFRadioGroup
                            smCols={4}
                            items={CarFuels}
                            onChange={handleChange}
                            label="Karburanti"
                            name="fuel_id"
                            selectedItem={parseInt(formData.fuel_id)}
                          />
                          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
                            Viti
                          </label>
                          <div className="grid grid-cols-2 gap-2">
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              defaultValue={formData?.yearFrom}
                              name="yearFrom"
                              placeholder="Nga viti"
                            />
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              label=""
                              defaultValue={formData?.yearTo}
                              name="yearTo"
                              placeholder="Deri ne vitin"
                            />
                          </div>

                          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
                            Cmimi
                          </label>
                          <div className="grid grid-cols-2 gap-2">
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              defaultValue={formData?.minPrice}
                              name="minPrice"
                              placeholder="Cmimi minimum"
                            />
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              label=""
                              defaultValue={formData?.maxPrice}
                              name="maxPrice"
                              placeholder="Cmimi maksimum"
                            />
                          </div>
                          <EFRadioGroup
                            items={CarCambios}
                            onChange={handleChange}
                            label="Kambio"
                            name="cambio_id"
                            selectedItem={parseInt(formData.cambio_id)}
                          />
                          <SelectBox
                            className="mt-2"
                            onSelectChange={(e: any) =>
                              handleChange(e, "city_id")
                            }
                            defaultValue={parseInt(formData?.city_id)}
                            items={Cities}
                            label="Zgjidh qytetin"
                          />
                          {!exclude?.includes("shitesi") && (
                            <EFRadioGroup
                              smCols={2}
                              items={CarSellerTypes}
                              onChange={handleChange}
                              label="Shitesi"
                              name="seller_id"
                              selectedItem={parseInt(formData.seller_id)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-3 bg-zinc-200 dark:bg-zinc-900">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setOpen(false)}
                      >
                        Mbyll
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700"
                        onClick={() => submitdata()}
                      >
                        Filtro pronat
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

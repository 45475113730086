
import { getCookie } from "./helpers";

function getHeader(headers: any) {
    let token = getCookie("authToken");
    if (token) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    // headers.set("Language", language);
    return headers;
}
export default getHeader;
import EFInput from "../../../components/general/EFInput";
import { useEffect, useState } from "react";
import {
  useFetchPersonQuery,
  useSavePersonMutation,
} from "../../../store/account/cars/accountCarsStore";
import Loading from "../../../components/general/Loading";
import { useDispatch } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import { Cities } from "../../../services/constants";
import SingleSelect from "../../../components/general/SingleSelect";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Individ", href: "/account/properties/agent/profile", current: false },
  { name: "Profili", href: "#", current: true },
];

export default function CarPersonProfile() {
  //#region rtk
  const { data, isFetching } = useFetchPersonQuery({});
  const [savePerson] = useSavePersonMutation();

  console.log(data)
  //#endregion

  //#region costants
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>();
  //#endregion

  const handleChange = (e: any, key: string) => {
    let value = e;
    if (key === "city_id") {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response: any = await savePerson(formData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  useEffect(() => {
    if (!isFetching && data) {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([key, value]) => value !== null && value !== undefined
        )
      );
      setFormData(filteredData);
    }
  }, [isFetching, data]);

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-9">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <EFInput
              onChange={handleChange}
              label="Emri i plote"
              required={true}
              defaultValue={formData?.name}
              name="name"
              validations={errors}
            />
            <EFInput
              onChange={handleChange}
              label="Email"
              required={true}
              defaultValue={formData?.email}
              name="email"
              validations={errors}
              hasIcon={true}
            />
            <EFInput
              onChange={handleChange}
              label="Telefon"
              required={true}
              defaultValue={formData?.phone}
              name="phone"
              validations={errors}
            />
            <SingleSelect
              name="city_id"
              required={true}
              onSelectChange={(e: any) => handleChange(e, "city_id")}
              defaultValue={formData?.city_id ?? -1}
              items={Cities}
              isFetching={isFetching}
              validations={errors}
              label="Zgjidh qytetin"
            />
          </div>
          <button
            type="button"
            className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
            onClick={handleSubmit}
          >
            Ruaj te dhenat
          </button>
        </div>
      </div>
    </>
  );
}

import React from "react";
import AccountSidebar from "../AccountSidebar";
import { useSelector } from "react-redux";
import NotificationAlert from "../../../general/NotificationAlert";
import { LayoutSelectorProps, NavigationItem } from "../../../types/General";

import {
  ArrowsUpDownIcon,
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  HomeModernIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

const CarAgencyLayout: React.FC<LayoutSelectorProps> = ({ children }) => {
  //#region constants
  const location = useLocation();

  let navigation: NavigationItem[] = [
    {
      id: 1,
      name: "Llogaria kryesore",
      href: "/account/main/dashboard",
      icon: HomeIcon,
      role: [],
    },
    {
      id: 2,
      name: "Dashboard",
      href: "/account/cars/dashboard",
      icon: HomeModernIcon,
      role: [],
      current: location.pathname === "/account/cars/dashboard",
    },
    {
      id: 3,
      name: "Profili",
      href: "/account/cars/agency/profile",
      icon: UserIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/profile",
    },
    {
      id: 4,
      name: "Shto makine",
      href: "/account/cars/agency/create",
      icon: PlusCircleIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/create",
    },
    {
      id: 5,
      name: "Lista e automjeteve",
      href: "/account/cars/agency/car-list",
      icon: ListBulletIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/car-list",
    },
    {
      id: 6,
      name: "Makinat e ruajtura",
      href: "/account/cars/agency/saved",
      icon: HeartIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/saved",
    },
    {
      id: 7,
      name: "Mesazhe",
      href: "/account/cars/agency/contacts",
      icon: ChatBubbleBottomCenterTextIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/contacts",
    },
    {
      name: "Kreditet",
      href: "/account/cars/agency/transactions",
      icon: ArrowsUpDownIcon,
      role: [],
      current: location.pathname === "/account/cars/agency/transactions",
    },
    { id: 9, name: "Reports", href: "#", icon: ChartPieIcon, role: [] },
  ];

  const generalData = useSelector((state: any) => state.main.generalData);

  if (generalData?.roles) {
    if (!generalData.roles.includes("propertyAgency")) {
      navigation = navigation.filter(
        (item) => !item?.role?.includes("propertyAgency")
      );
    }
  }
  //#endregion

  return (
    <div>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default CarAgencyLayout;

import ServerComponent from "./TestServerComponent";

export default  function TestComponent() {
  return (
    <>
     <h1>Client component first</h1>
      {ServerComponent()}
    </>
  );
}


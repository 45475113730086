import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../src/services/apiHeaders";

export const systemApi = createApi({
  reducerPath: "system_r",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL + "system",
    fetchFn: async (...args) => {
      return fetch(...args);
    },
    prepareHeaders(headers) {
      return getHeader(headers);
    },
  }),
  tagTypes: ["GetUsers", "GetPropertyAgencies", "GetProperties"],
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: (params) => {
        return {
          url: "users",
          method: "GET",
          params: params,
        };
      },
      providesTags: ["GetUsers"],
    }),
    enableUsers: builder.mutation({
      query: (body) => ({
        url: "/enable-users",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetUsers"],
    }),
    
    fetchPropertyAgencies: builder.query({
      query: (params) => {
        return {
          url: "properties/agencies",
          method: "GET",
          params: params,
        };
      },
      providesTags: ["GetPropertyAgencies"],
    }),

    enablePropertyAgecies: builder.mutation({
      query: (body) => ({
        url: "/properties/agencies-enable",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetPropertyAgencies"],
    }),
    fetchProperties: builder.query({
      query: (params) => {
        return {
          url: "properties",
          method: "GET",
          params: params,
        };
      },
      providesTags: ["GetProperties"],
    }),
    refreshProperty: builder.mutation({
      query: (body) => ({
        url: "/properties/refresh",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetProperties"],
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useFetchPropertiesQuery,
  useFetchPropertyAgenciesQuery,
  useRefreshPropertyMutation,
  useEnableUsersMutation,
  useEnablePropertyAgeciesMutation
} = systemApi;

import React, { ReactNode } from "react";
import AccountSidebar from "../AccountSidebar";
import { useSelector } from "react-redux";
import NotificationAlert from "../../../general/NotificationAlert";

import {
  ChartPieIcon,
  HeartIcon,
  HomeIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UserIcon,
  UsersIcon,
  ChatBubbleBottomCenterTextIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

interface NavigationItem {
  id: number;
  name: string;
  href: string;
  icon: React.ElementType; // Assuming icons are React components
  role: string[];
  logout?: boolean;
  current?: boolean;
}

interface LayoutSelectorProps {
  children: ReactNode;
}

const PropertiesAgencyLayout: React.FC<LayoutSelectorProps> = ({
  children,
}) => {
  //#region costants
  const location = useLocation();
  const generalData = useSelector((state: any) => state.main.generalData);

  let navigation: NavigationItem[] = [
    {
      id: 1,
      name: "Paneli kryesor",
      href: "/account/main/dashboard",
      icon: HomeIcon,
      role: [],
      current: location.pathname === "/account/main/dashboard",
    },
    {
      id: 2,
      name: "Profili i agjensise",
      href: "/account/properties/agency/profile",
      icon: UserIcon,
      role: [],
      current: location.pathname === "/account/properties/agency/profile",
    },
    {
      id: 3,
      name: "Shto prone",
      href: "/account/properties/agency/create-property",
      icon: PlusCircleIcon,
      role: [],
      current:
        location.pathname === "/account/properties/agency/create-property",
    },
    {
      id: 4,
      name: "Lista e pronave",
      href: "/account/properties/agency/property-list",
      icon: ListBulletIcon,
      role: [],
      current: location.pathname === "/account/properties/agency/property-list",
    },
    {
      id: 5,
      name: "Pronat e ruajtura",
      href: "/account/properties/agency/saved",
      icon: HeartIcon,
      role: [],
      current: location.pathname === "/account/properties/agency/saved",
    },
    {
      id: 6,
      name: "Agjentet",
      href: "/account/properties/agency/agents",
      icon: UsersIcon,
      role: ["propertyAgency"],
      current: location.pathname === "/account/properties/agency/agents",
    },
    {
      id: 7,
      name: "Mesazhe",
      href: "/account/properties/agency/contacts",
      icon: ChatBubbleBottomCenterTextIcon,
      role: [],
      current: location.pathname === "/account/properties/agency/contacts",
    },
    {
      id: 8,
      name: "Kreditet",
      href: "/account/properties/agency/transactions",
      icon: ArrowsUpDownIcon,
      role: [],
      current: location.pathname === "/account/properties/agency/transactions",
    },
    {
      id: 9,
      name: "Raporte",
      href: "#",
      icon: ChartPieIcon,
      role: [],
      current: location.pathname === "#",
    },
  ];

  if (generalData?.roles) {
    if (!generalData.roles.includes("propertyAgency")) {
      navigation = navigation.filter(
        (item) => !item.role.includes("propertyAgency")
      );
    }
  }
  //#endregion

  return (
    <div>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default PropertiesAgencyLayout;

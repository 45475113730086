import {
  MoonIcon,
  SunIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import {
  getCookie,
  isNotNullOrUndefined,
  removeCookie,
  setCookie,
} from "../../../services/helpers";
import { useLogoutMutation } from "../../../store/auth/AuthStore";
import { showNotification } from "../../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Bars3BottomRightIcon } from "@heroicons/react/24/solid";

const userNavigation = [
  { name: "Profili", href: "/account/main/dashboard" },
  { name: "Paneli kryesor", href: "/account/main/dashboard" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function Header({ navigation }: { navigation: any }) {
  //#region constants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region rtk
  const [logout] = useLogoutMutation();
  //#endregion

  //#region states
  const [showAccount, setShowAccount] = useState(
    isNotNullOrUndefined(getCookie("authToken"))
  );
  const [theme, setTheme] = useState(getCookie("theme") === "dark");
  const [menu, setMenu] = useState(false);
  //#endregion

  //#region methods
  const handleLogout = async () => {
    try {
      const result: any = await logout({});
      if (result.data.status === 200) {
        removeCookie("authToken");
        setShowAccount(false);
        dispatch(showNotification({ msg: result.data.message, visible: true }));
        window.location.href = "/";
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };

  const handleTheme = async () => {
    setTheme(!theme);

    if (theme) {
      document.documentElement.classList.remove("dark");
      setCookie("theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      setCookie("theme", "dark");
    }
  };

  const onItemClick = (url: any) => {
    navigate(url);
    setMenu(false);
  };
  //#endregion

  //#region methods
  useEffect(() => {
    const menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((item) => {
      const href = item.getAttribute("href");
      item.classList.remove("text-red-700");
      if (href === window.location.pathname) {
        item.classList.add("text-red-700");
      }
    });
  }, [window.location.pathname]);
  //#endregion

  return (
    <div className="fixed z-20 top-0 left-0 right-0 md:bg-white  dark:bg-zinc-900 text-gray-600 border-b">
      <div className="mx-auto max-w-screen-2xl bg-white dark:bg-zinc-900 px-2 2xl:px-0">
        <div className="flex h-14 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/">
                {theme ? (
                  <img
                    className="h-8 w-auto"
                    src="/logo-white.png"
                    alt="Your Company"
                  />
                ) : (
                  <img
                    className="h-8 w-auto"
                    src="/logo-dark.png"
                    alt="Your Company"
                  />
                )}
              </Link>
            </div>
            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-2 dark:text-white">
              {navigation.map((item: any) => (
                <Link
                  to={item.href}
                  key={item.name}
                  className={classNames(
                    item.current ? "" : "",
                    "px-2 py-2 text-sm font-medium hover:text-red-700 menu-item"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center space-x-3">
            {!showAccount && (
              <>
                <div
                  className="flex rounded-full text-sm group focus:outline-none cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  <span className="mr-1 mt-0.5 dark:text-white hover:text-red-700">
                    Identifikohu
                  </span>
                </div>
                <div
                  className="flex rounded-full text-sm group focus:outline-none cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  <span className="mr-1 mt-0.5 dark:text-white hover:text-red-700">
                    Regjistrohu
                  </span>
                </div>
                <div
                  className="rounded-full text-sm group focus:outline-none cursor-pointer hidden md:flex"
                  onClick={handleTheme}
                >
                  {!theme && (
                    <MoonIcon
                      className="block h-6 w-6 dark:text-white hover:text-red-700"
                      aria-hidden="true"
                    />
                  )}
                  {theme && (
                    <SunIcon
                      className="block h-6 w-6 dark:text-white hover:text-red-700"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </>
            )}
            {showAccount && (
              <div className="flex md:ml-4 md:flex-shrink-0 md:items-center relative group">
                <div className="flex rounded-full text-sm group focus:outline-none cursor-pointer">
                  <span className="mr-2 mt-1.5 hidden md:flex">Llogaria</span>
                  <UsersIcon
                    className="block h-6 w-6 mt-1"
                    aria-hidden="true"
                  />
                </div>
                <div className="absolute hidden top-7 right-2 group-hover:block">
                  <div className="mt-5 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          "block px-4 py-2 text-sm text-gray-700",
                          "hover:bg-gray-100"
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                    <span
                      className={classNames(
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                      onClick={handleLogout}
                    >
                      Dil
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="mx-2 flex items-center md:hidden">
              <div
                className="flex rounded-full text-sm group focus:outline-none cursor-pointer"
                onClick={handleTheme}
              >
                {!theme && (
                  <MoonIcon
                    className="block h-6 w-6 dark:text-white hover:text-red-700"
                    aria-hidden="true"
                  />
                )}
                {theme && (
                  <SunIcon
                    className="block h-6 w-6 dark:text-white hover:text-red-700"
                    aria-hidden="true"
                  />
                )}
              </div>
              <button className="relative inline-flex items-center justify-center p-2 text-gray-700 focus:outline-none">
                <span className="absolute" />
                <span className="sr-only">Open main menu</span>
                {menu ? (
                  <XMarkIcon
                    className="block h-6 w-6"
                    aria-hidden="true"
                    onClick={() => setMenu(false)}
                  />
                ) : (
                  <Bars3BottomRightIcon
                    className="block h-6 w-6"
                    aria-hidden="true"
                    onClick={() => setMenu(true)}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden h-screen bg-zinc-100 bg-zinc-900 ${
          menu ? "slide-in" : "hidden"
        }`}
      >
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {navigation.map((item: any) => (
            <button
              key={item.name}
              onClick={() => onItemClick(item.href)}
              className={classNames(
                "text-white block px-3 py-2 text-base font-medium w-full text-left"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Header;

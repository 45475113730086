import imageCompression from "browser-image-compression";

export function beautifyUrl(title: string) {
    // Sanitize the title to make it URL-friendly by converting to lowercase and replacing spaces with hyphens
    return title
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')  // Remove any non-alphanumeric characters (except spaces and hyphens)
        .trim()
        .replace(/\s+/g, '-')  // Replace spaces with hyphens
        .replace(/-+/g, '-');  // Remove multiple consecutive hyphens
};

export function setCookie(key: string, value: any, days: number = 7) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${key}=${value};expires=${expires.toUTCString()};domain: '.localhost:3000';path=/`;
}

export function getCookie(cookieName: string) {

    if (typeof document !== "undefined") {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
    }
    return null;
}
export function isLogedIn() {

    return isNotNullOrUndefined(getCookie('authToken'));
}


export function removeCookie(key: string) {
    const expires = new Date(0);
    if (typeof document !== "undefined") {
        document.cookie = `${key}=;expires=${expires.toUTCString()};path=/`;
    }
}

export function isNotNullOrUndefined(props: any) {
    try {
        if (props !== null && props !== undefined && props !== "") {
            return true;
        }
    } catch (error) {

    }
    return false;
}

export function carTitle(car: any) {
    try {
        if (car !== null) {
            return car.make.name + ' ' + car.model.name + ' ' + car.engine + ' ' + car.potenca + " HP";
        }
    } catch (error) {
        return '';
    }
    return '';
}

export function truncateString(str: string, num: number) {
    if (isNotNullOrUndefined(str)) {
        if (str?.length <= num) {
            return str;
        } else {
            return str.slice(0, num) + '...';
        }
    }
    return str;
}

export function isNumeric(value: any) {
    return /^\d+$/.test(value);
};
export function getUrlParams() {
    if (typeof window !== "undefined") {
        const params = new URLSearchParams(window.location.search);
        const decodedParams: any = {};
        params.forEach((value, key) => {
            decodedParams[key] = decodeURIComponent(value);
        });
        return decodedParams;
    }
    return null;
}

export function mapAddress(address: string, city: string, country = 'Albania') {
    return `${address}, ${city}, ${country}`;
};

export function price(price: any) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR' // Change the currency code as needed
    }).format(price);
}
export function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function getId() {
    if (typeof window !== "undefined") {
        const url = window.location.href; // Get the current URL
        const parts = url.split('/'); // Split the URL by '/'
        const lastPart = parts[parts.length - 1]; // Get the last part of the URL
        return lastPart;
    }
    return null;
}

export function findInObjects(items: any[], key: string, name: string, id: number) {
    if (items?.length > 0) {
        const foundItem = items.find(x => x[key] === id);
        if (foundItem) {
            return foundItem[name];
        }
    }

    return null;
}

export async function compressImages(images: any, maxWidthOrHeight = 1920, quality = 1, maxSizeMB = 1) {

    const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true,
        quality: quality,
    };

    if (images.length > 0) {
        const compressedImages = [];
        for (const image of images) {
            const compressedBlob = await imageCompression(image, options);
            const imageType = image.type;
            compressedImages.push(new File([compressedBlob], image.name, { type: imageType }));
        }
        return compressedImages;
    }

    return null;
}

export function scrollToDiv(id: string, ofset: number) {

    const targetDiv = document.getElementById(id);

    if (targetDiv) {
        const offset = targetDiv.getBoundingClientRect().top - ofset;
        window.scrollBy({ top: offset, left: 0, behavior: 'smooth' });
    }
};

export function copyUrl() {
    return navigator.clipboard.writeText(window.location.href)
};

export function getRefValue(ref: any) {
    try {
        if (ref && ref.current) {
            const element = ref.current;
            if (element) {
                return element.value;
            }
            return null;
        }
    } catch (error) {
        return null;
    }
};
import { useEffect, useState, Fragment, useRef } from "react";
import {
  findInObjects,
  getUrlParams,
  price,
  scrollToDiv,
  truncateString,
} from "../../../services/helpers";
import Pagination from "../../../components/general/Pagination";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import Loading from "../../../components/general/Loading";
import {
  useDeletePropertyMutation,
  useFetchPropertiesQuery,
  useRefreshPropertyMutation,
} from "../../../store/account/properties/accountPropertiesStore";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { useCalculateHeight } from "../../../hooks/calculateHeight";
import { PropertyFurnitures, PropertyRooms } from "../../../services/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchGeneralDataQuery } from "../../../store/main/MainStore";
import PropertyAccountFilters from "../../../components/account/filters/PropertyAccountFilters";

const pages = [
  { name: "Prona", href: "/account/properties/dashboard", current: false },
  { name: "Lista", href: "#", current: true },
];

export default function PropertyList() {
  //#region costants
  const params = getUrlParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const height = useCalculateHeight(230);
  const navigate = useNavigate();

  let urlCreate = "/account/properties/person/";
  if (location.pathname.startsWith("/account/properties/agency"))
    urlCreate = "/account/properties/agency/";
  if (location.pathname.startsWith("/account/properties/agent"))
    urlCreate = "/account/properties/agent/";
  //#endregion

  //#region rtk
  const { data, isFetching, refetch } = useFetchPropertiesQuery(params);
  const { refetch: refetchGeneralData } = useFetchGeneralDataQuery({});
  const [refreshProperty] = useRefreshPropertyMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  //#endregion

  //#region states
  const [page, setPage] = useState(params);
  const [open, setOpen] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  //#endregion

  //#region refs
  const refCheckbox = useRef<any>(null);
  const cancelButtonRef = useRef(null);
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(page);
    scrollToDiv("properties", 80);
  };
  const handleRefresh = (id: any) => {
    setOpen(1);
    setSelectedRows([...selectedRows, id]);
  };
  const handleRefreshProperty = async () => {
    setOpen(0);
    const response: any = await refreshProperty({ ids: selectedRows });
    if (response.data?.status) {
      refetch();
      refetchGeneralData();
      setSelectedRows([]);
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    } else {
      dispatch(showNotification({ msg: "Dicka shkoi keq!", visible: true }));
    }
  };
  const handleDelete = (id: any) => {
    setOpen(2);
    setSelectedRows([...selectedRows, id]);
  };
  const handleDeleteProperties = async () => {
    setOpen(0);
    const response: any = await deleteProperty({ ids: selectedRows });
    if (response.data?.status) {
      refetch();
      setSelectedRows([]);
      dispatch(showNotification({ msg: "U fshi me suksess!", visible: true }));
    } else {
      dispatch(showNotification({ msg: "Dicka shkoi keq!", visible: true }));
    }
  };
  const RefreshModal = () => {
    return (
      <Transition.Root show={open === 1} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(0)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto sm:pl-64">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Rifresko pronen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Jeni te sigurt?</p>
                        <p className="text-sm text-gray-500">
                          Ky veprim eshte me pagese e cila do te merret nga
                          balanca juaj dhe kushton <strong>0.5 Euro</strong>.
                        </p>
                        <p className="text-sm text-gray-500">
                          Ky veprim modifikon daten e postimit te postit gje e
                          cila rendit postimet ne aplikacionin tone.
                          <strong>Kujdes</strong> kjo nuk do te thote qe postimi
                          juaj do te renditet me patjeter i pari.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-neutral-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700 sm:w-auto"
                      onClick={handleRefreshProperty}
                    >
                      Rifresko
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(0)}
                      ref={cancelButtonRef}
                    >
                      Anullo
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  const DeleteModal = () => {
    return (
      <Transition.Root show={open === 2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(0)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto sm:pl-64">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Fshini pronen
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Jeni te sigurt?</p>
                        <p className="text-sm text-gray-500">
                          Ju jeni duke fshire pronen, dhe ky veprim eshte i pa
                          rikthyeshem. Konfirmoni veprimin ose klikoni anullo
                          per ta anulluar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-neutral-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700 sm:w-auto"
                      onClick={handleDeleteProperties}
                    >
                      Konfirmo
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(0)}
                      ref={cancelButtonRef}
                    >
                      Anullo
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  function toggleAll() {
    setSelectedRows(checked ? [] : data?.data.map((item: any) => item.id));
    setChecked(!checked);
  }
  const onFiltersChange = async (data: any) => {
    const params = new URLSearchParams(data);
    if (params.has("page") && params.get("page") !== "1") {
      params.set("page", "1");
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(params);
    setSelectedRows([]);
  };

  function handleSelect(e: any, property: any) {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, property.id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== property.id));
    }
  }
  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);
  //#endregion

  if (isFetching && !data?.meta) {
    return <Loading />;
  }

  return (
    <>
      <div id="properties">
        <RefreshModal />
        <DeleteModal />
        <div className="flex justify-between items-center mb-4 rounded-sm">
          <div className="flex overflow-x-auto">
            {selectedRows.length > 0 && (
              <div className="left-14 top-0 flex items-center space-x-3 sm:left-12 mr-3">
                <button
                  onClick={() => setOpen(1)}
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-neutral-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
                >
                  Rifresko
                </button>
                <button
                  onClick={() => setOpen(2)}
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-red-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
                >
                  Fshi
                </button>
              </div>
            )}
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-neutral-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
              onClick={() => setOpenFilters(!openFilters)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>
              <span className="mt-px capitalize min-w-max">Filtrat</span>
            </button>
            <div className="ermal">
              <PropertyAccountFilters
                onFiltersChange={onFiltersChange}
                open={openFilters}
                setOpenFilters={setOpenFilters}
              />
            </div>
          </div>
          <div className="sm:mt-1 text-right mt-0 ml-2 flex-none">
            <button
              onClick={() => navigate(urlCreate + "create-property")}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-neutral-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
            >
              <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Shto prone
            </button>
          </div>
        </div>
        <div className="overflow-auto" style={{ height: height }}>
          <table className="min-w-full divide-y divide-neutral-300">
            <thead className="bg-neutral-300 dark:bg-neutral-800 dark:text-white sticky top-0 z-10">
              <tr>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  <input
                    type="checkbox"
                    className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 focus:ring-zinc-800"
                    ref={refCheckbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Titulli i prones
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Detaje
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Cmimi
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300  text-right py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 lg:pl-8"
                >
                  Veprime
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-300 dark:divide-neutral-800 bg-white dark:bg-neutral-800">
              {data?.data.map((property: any) => (
                <tr
                  key={property.id}
                  className={`${
                    selectedRows.includes(property.id)
                      ? "bg-neutral-300 dark:bg-neutral-900"
                      : "even:bg-neutral-100 dark:even:bg-neutral-900"
                  }`}
                >
                  <td className="relative px-7 sm:w-12 sm:px-6">
                    {selectedRows.includes(property.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-neutral-800 dark:bg-red-700 rounded-sm" />
                    )}
                    <input
                      type="checkbox"
                      className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-zinc-800 focus:ring-zinc-800"
                      value={property.id}
                      checked={selectedRows.includes(property.id)}
                      onChange={(e) => handleSelect(e, property)}
                    />
                  </td>
                  <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="flex items-center">
                      <div className="h-16 w-32 flex-shrink-0">
                        <img
                          className="h-16 w-32 rounded-sm object-cover"
                          src={property.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium">
                          {property.name}
                        </div>
                        <div className="mt-1 text-gray-500">
                          <div className="relative group inline-block">
                            <div
                              className="p-2 rounded"
                              dangerouslySetInnerHTML={{
                                __html: truncateString(property.desc, 50),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 dark:text-white">
                    <div className="ml-4">
                      <div className="font-medium">
                        <div className="flex justify-start text-gray-500 dark:text-white">
                          <p className="text-sm">
                            {" "}
                            {findInObjects(
                              PropertyRooms,
                              "id",
                              "name",
                              property.rooms
                            )}
                          </p>
                          <p className="text-sm italic px-1"> | </p>
                          <p className="text-sm">WC {property.baths}</p>
                          <p className="text-sm italic px-1"> | </p>
                          <p className="text-sm">
                            Siperfaqa {property.surface} m&sup2;
                          </p>
                          <p className="text-sm italic px-1"> | </p>
                          <p className="text-sm">Kati {property.floor}</p>
                          <p className="text-sm italic px-1"> | </p>
                          <p className="text-sm">
                            {findInObjects(
                              PropertyFurnitures,
                              "id",
                              "name",
                              property.furniture
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1 text-gray-500">
                        <p className="text-sm">
                          Krijuar <strong>{property.created}</strong>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div>{price(property.price)}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {property.status === 1 ? "Shitet" : "Qera"}
                    </span>
                  </td>
                  <td className="whitespace-nowrap flex px-3 py-5 text-sm text-gray-500 float-right">
                    <button
                      onClick={() => handleRefresh(property.id)}
                      className="inline-flex items-center rounded bg-sky-200 px-2 py-1 text-sm font-semibold text-gray-900 ml-1 ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 hover:bg-sky-400"
                    >
                      <ArrowPathIcon className="w-5 h-5 mr-1 p-0.5" />
                      Rifresko
                    </button>
                    <button
                      onClick={() =>
                        navigate(urlCreate + "property/" + property.id)
                      }
                      className="inline-flex items-center rounded bg-neutral-700 px-2 py-1 text-sm font-semibold text-white ml-1 ring-1 ring-inset ring-gray-300 dark:ring-0 hover:bg-neutral-800"
                    >
                      <PencilIcon className="w-5 h-5 mr-1 p-0.5" />
                      Modifiko
                    </button>
                    <button
                      onClick={() => handleDelete(property.id)}
                      type="button"
                      className="inline-flex items-center rounded bg-red-700 px-2 py-1 text-sm font-semibold text-white ml-1 ring-1 ring-inset ring-gray-300 dark:ring-0 hover:bg-red-600"
                    >
                      Fshi
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={data?.meta?.current_page ?? 1}
        from={data?.meta?.from ?? 0}
        to={data?.meta?.to ?? 0}
        total={data?.meta?.total ?? 0}
        last={data?.meta?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  );
}

import { useState } from "react";
import { useFetchPropertyAgentsQuery } from "../../../store/properties/PropertiesStore";
import Pagination from "../../../components/general/Pagination";
import { getUrlParams, scrollToDiv } from "../../../services/helpers";
import Loading from "../../../components/general/Loading";
import { useNavigate } from "react-router-dom";
import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline";

export default function Agents() {
  //#region costants
  const params = getUrlParams();
  const navigate = useNavigate();
  //#endregion

  //#region states
  const [page, setPage] = useState(params);
  const [formData] = useState<any>(params);
  //#endregion

  //#region rtk
  const { data, isFetching } = useFetchPropertyAgentsQuery(params);
  //#endregion

  if (isFetching && !data?.meta.total) {
    return <Loading />;
  }

  const handleChange = (e: any, key: string) => {
    let value = e;
    if (key === "fjaleKyce") {
      value = e.target.value;
      if (value === "") {
        const params = new URLSearchParams(page?.page);
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${params.toString()}`
        );
        setPage(params);
        return;
      }
    }
    setPage({
      ...page,
      [key]: value,
    });
  };

  const handleSearch = async () => {
    const params = new URLSearchParams(page);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(params);
  };

  const handlePageClick = async (page: number) => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      params.set("page", page.toString());
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`
      );
      setPage(page);
      scrollToDiv("agents", 80);
    }
  };
  //#endregion

  return (
    <div className="bg-white">
      <div className="bg-zinc-200 py-2">
        <div className="mx-auto max-w-screen-2xl px-2 sm:px-0">
          <div className="flex justify-start pt-2">
            <p className="text-sm text-gray-500">Prona</p>
            <p className="text-sm text-gray-500 px-2">|</p>
            <p className="text-sm text-gray-500"> Agjente </p>
          </div>
          <h3 className="text-5xl ui-serif sm:my-4">Lista e agjenteve</h3>
          <div className="flex my-8">
            <div className="">
              <input
                autoComplete="off"
                onChange={(e) => handleChange(e, "fjaleKyce")}
                placeholder="Krko sipas emrit"
                type="text"
                name="fjaleKyce"
                defaultValue={formData?.fjaleKyce}
                className=" w-full px-3 py-2 border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-gray-500 invalid:border-red-500 invalid:text-red-600"
              />
            </div>
            <button
              onClick={handleSearch}
              type="button"
              className="ml-4 inline-flex justify-center p-2 rounded-md bg-neutral-800 px-3  text-sm font-semibold text-white"
            >
              Kerko
            </button>
          </div>
        </div>
      </div>
      <div
        className="mx-auto max-w-screen-2xl px-2 sm:px-0 py-5 2xl:px-0 sm:py-5"
        id="agents"
      >
        <ul className="mx-auto md:mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {data?.data.map((agent: any) => (
            <li key={agent.id} className="border pb-2">
              <img
                alt=""
                src={agent.image}
                className="mx-auto w-full object-contain bg-gray-100 md:aspect-square cursor-pointer"
                onClick={() => navigate(`/prona/agjent/${agent.id}`)}
              />
              <h3
                className="px-2 mt-2 text-base/7 font-semibold tracking-tight text-gray-900 cursor-pointer"
                onClick={() => navigate(`/prona/agjent/${agent.id}`)}
              >
                {agent.name}
              </h3>
              <p className="px-2 text-sm/6 text-gray-600">
                Agjent pronash ({agent.properties_count} prona te listuara)
              </p>
              <p className="px-2 text-sm/6 text-gray-600 flex items-center">
                <PhoneIcon className="w-4 h-4 mr-1" />
                {agent.phone}
              </p>
              <p className="px-2 text-sm/6 text-gray-600 flex items-center">
                <EnvelopeIcon className="w-4 h-4 mr-1" />
                {agent.email}
              </p>
              <p
                className="px-2 text-sm/6 text-gray-600 flex items-center cursor-pointer"
                onClick={() => navigate(`/prona/agjent/${agent.id}`)}
              >
                <UserIcon className="w-4 h-4 mr-1" />
                Shiko profilin
              </p>
            </li>
          ))}
        </ul>
        <Pagination
          currentPage={data?.meta.current_page ?? 1}
          from={data?.meta.from ?? 0}
          to={data?.meta.to ?? 0}
          total={data?.meta.total ?? 0}
          last={data?.meta.last_page ?? 0}
          onPageClick={handlePageClick}
        />
      </div>
    </div>
  );
}

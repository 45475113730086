import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IdName } from "../../components/types/General";

export interface IProperties {
  carData: {
    makes: IdName[];
    models: IdName[];
  };
}

const initialState: IProperties = {
  carData: {
    makes: [],
    models: []
  },
};

// Create a slice with correct payload type
export const CarsSlice = createSlice({
  name: "cars_reducer",
  initialState,
  reducers: {
    // Corrected payload type to match the structure of `carData`
    setCarData: (state, action: PayloadAction<{ makes: IdName[]; models: IdName[] }>) => {
      // Directly update the carData property with the payload
      state.carData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCarData } = CarsSlice.actions;

export default CarsSlice.reducer;

import { Fragment, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Cities,
  PropertyBathrooms,
  PropertyDocuments,
  PropertyFurnitures,
  PropertyRooms,
  PropertyStatuses,
  PropertyTypes,
} from "../../../services/constants";
import SingleSelect from "../../general/SingleSelect";
import EFInput from "../../general/EFInput";
import EFRadioGroup from "../../general/EFRadioGroup";
import { Zones } from "../../../../src/services/zones";
import {
  findInObjects,
  getUrlParams,
  isNotNullOrUndefined,
} from "../../../../src/services/helpers";
import SelectBox from "../../general/SelectBox";

export default function PropertyModalFilters({
  onFiltersChange,
}: {
  onFiltersChange: any;
}) {
  const params = getUrlParams();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(params);
  const [cityZones, setCityZones] = useState<any>();

  const handleChange = (e: any, key: string) => {
    let value = e;
    if (key === "fjaleKyce" || key === "minPrice" || key === "maxPrice")
      value = e.target.value;
    if (key === "city") setCityZones(Zones.filter((x) => x.city_id === value));
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  //Clear filters by key from state and refilter data
  const clear = (keyToRemove: string) => {
    const updatedFormData = { ...formData };
    delete updatedFormData[keyToRemove];
    if (keyToRemove === "city") delete updatedFormData["zone"];
    setFormData(updatedFormData);
    onFiltersChange(updatedFormData);
  };

  const submitdata = () => {
    onFiltersChange(formData);
    setOpen(false);
  };
  const clearFilters = () => {
    onFiltersChange({});
    setOpen(false);
    setFormData({});
  };
  return (
    <>
      <div className="bg-zinc-100 dark:bg-zinc-900">
        <hr className="dark:border-neutral-800" />
        <div className="flex mx-auto max-w-screen-2xl px-2 2xl:px-0 py-2">
          <button
            type="button"
            className="relative text-xs rounded-md bg-white border border-zinc-300 dark:border-zinc-600 dark:text-white flex px-2 py-1 h-7"
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <span className="mt-px capitalize min-w-max">Filtrat</span>
          </button>
          <div className="overflow-x-auto flex ml-2">
            {params.status && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("status")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {parseInt(params.status) === 1
                    ? "Prona ne shitje"
                    : "Prona me qera"}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.fjaleKyce && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("fjaleKyce")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {params.fjaleKyce}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.type && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("type")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    PropertyTypes,
                    "id",
                    "name",
                    parseInt(params.type)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.rooms && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("rooms")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    PropertyRooms,
                    "id",
                    "name",
                    parseInt(params.rooms)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.bathrooms && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("bathrooms")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  tualete:
                  {findInObjects(
                    PropertyBathrooms,
                    "id",
                    "name",
                    parseInt(params.bathrooms)
                  )}{" "}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.furniture && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("furniture")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    PropertyFurnitures,
                    "id",
                    "name",
                    parseInt(params.furniture)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.documents && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("documents")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(
                    PropertyDocuments,
                    "id",
                    "name",
                    parseInt(params.documents)
                  )}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.minPrice && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("minPrice")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    Cmim min: {params.minPrice}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.maxPrice && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("maxPrice")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  <span className="mt-0.5 capitalize min-w-max">
                    Cmim max: {params.maxPrice}
                  </span>
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.city && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("city")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(Cities, "id", "name", parseInt(params.city))}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {params.zone && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                onClick={() => clear("zone")}
              >
                <span className="mt-0.5 capitalize min-w-max">
                  {findInObjects(Zones, "id", "name", parseInt(params.zone))}
                </span>
                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
              </button>
            )}
            {Object.keys(params).length > (params.page ? 1 : 0) && (
              <button
                type="button"
                className="relative border dark:bg-neutral-800 dark:text-gray-200 dark:border-zinc-700 text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
              >
                <span className="mt-0.5 capitalize min-w-max">
                  Hiq te gjithe filtrat
                </span>
                <XMarkIcon
                  className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm"
                  onClick={() => clearFilters()}
                />
              </button>
            )}
          </div>
        </div>
        <hr className="dark:border-neutral-800" />
      </div>
      <Dialog open={open} onClose={setOpen} className="relative z-10 overflow-hidden">
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-xl transform transition duration-500 ease-in-out data-[closed]:-translate-x-full translate-x-0 sm:duration-700"
              >
                <div className="flex h-full flex-col  bg-white pt-14 shadow-xl">
                  <form className="flex h-full flex-col divide-y divide-zinc-200 dark:divide-zinc-700 bg-white dark:bg-zinc-900 shadow-xl">
                    <div className="px-4 py-4 sm:px-6 bg-zinc-200 w-full dark:bg-zinc-900 dark:text-white">
                      <div className="flex items-center justify-between">
                        <div className="font-semibold leading-6">
                          Filtro pronat
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="h-0 flex-1 overflow-y-auto pb-8  pt-2">
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <EFRadioGroup
                            items={PropertyStatuses}
                            onChange={handleChange}
                            name="status"
                            selectedItem={formData.status}
                            smCols={2}
                          />

                          <EFInput
                            className="mt-2"
                            onChange={handleChange}
                            label="Fjalekyce"
                            defaultValue={formData?.fjaleKyce}
                            name="fjaleKyce"
                          />
                          <SelectBox
                            className="mt-2"
                            onSelectChange={(e: any) => handleChange(e, "type")}
                            defaultValue={parseInt(formData?.type)}
                            items={PropertyTypes}
                            label="Lloji i prones"
                          />

                          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
                            Cmimi
                          </label>
                          <div className="grid grid-cols-2 gap-2">
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              defaultValue={formData?.minPrice}
                              name="minPrice"
                              placeholder="Cmimi minimum"
                            />
                            <EFInput
                              onChange={handleChange}
                              type="number"
                              label=""
                              defaultValue={formData?.maxPrice}
                              name="maxPrice"
                              placeholder="Cmimi maksimum"
                            />
                          </div>

                          <SingleSelect
                            className="mt-2"
                            onSelectChange={(e: any) => handleChange(e, "city")}
                            defaultValue={parseInt(formData?.city)}
                            items={Cities}
                            label="Zgjidh qytetin"
                          />

                          {isNotNullOrUndefined(formData) && formData.city && (
                            <SelectBox
                              className="mt-2"
                              onSelectChange={(e: any) =>
                                handleChange(e, "zone")
                              }
                              defaultValue={parseInt(formData?.zone)}
                              items={cityZones}
                              label="Zgjidh zonen"
                            />
                          )}

                          {(parseInt(formData.type) === 1 ||
                            parseInt(formData.type) === 4) && (
                            <EFRadioGroup
                              items={PropertyRooms}
                              onChange={handleChange}
                              label="Dhoma"
                              name="rooms"
                              selectedItem={formData.rooms}
                            />
                          )}

                          {(parseInt(formData.type) === 1 ||
                            parseInt(formData.type) === 4) && (
                            <EFRadioGroup
                              items={PropertyBathrooms}
                              onChange={handleChange}
                              label="Tualete"
                              name="bathrooms"
                              selectedItem={formData.bathrooms}
                            />
                          )}

                          {(parseInt(formData.type) === 1 ||
                            parseInt(formData.type) === 4) && (
                            <EFRadioGroup
                              items={PropertyFurnitures}
                              onChange={handleChange}
                              label="Mobilimi"
                              name="furniture"
                              selectedItem={formData.furniture}
                            />
                          )}
                          <EFRadioGroup
                            items={PropertyDocuments}
                            onChange={handleChange}
                            label="Dokumentacioni"
                            name="documents"
                            selectedItem={formData.documents}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-3 bg-zinc-200 dark:bg-zinc-900">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setOpen(false)}
                      >
                        Mbyll
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700"
                        onClick={() => submitdata()}
                      >
                        Filtro pronat
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

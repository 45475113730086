import { useEffect, useState, useRef } from "react";
import {
  getUrlParams,
  isNotNullOrUndefined,
  scrollToDiv,
} from "../../../services/helpers";
import Pagination from "../../../components/general/Pagination";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import Loading from "../../../components/general/Loading";
import {
  useEnablePropertyAgeciesMutation,
  useFetchPropertyAgenciesQuery,
} from "../../../store/system/SystemStore";
import {
  CheckBadgeIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useCalculateHeight } from "../../../hooks/calculateHeight";
import { useLocation, useNavigate } from "react-router-dom";
import EFInput from "../../../components/general/EFInput";

const pages = [
  { name: "System", href: "#", current: false },
  { name: "Properties", href: "#", current: false },
  { name: "Agencies", href: "#", current: true },
];

export default function Agencies() {
  //#region costants
  const params = getUrlParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const height = useCalculateHeight(210);
  const navigate = useNavigate();

  let urlCreate = "/account/cars/client/";
  if (location.pathname.startsWith("/account/cars/agency"))
    urlCreate = "/account/cars/agency/";
  //#endregion

  //#region states
  const [page, setPage] = useState(params);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  //#endregion

  //#region rtk
  const { data, isFetching } = useFetchPropertyAgenciesQuery(params);
  const [enable] = useEnablePropertyAgeciesMutation();
  //#endregion

  //#region refs
  const refCheckbox = useRef<any>(null);
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(page);
    scrollToDiv("properties", 80);
  };

  function toggleAll() {
    setSelectedRows(checked ? [] : data?.data.map((item: any) => item.id));
    setChecked(!checked);
  }

  function handleSelect(e: any, property: any) {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, property.id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== property.id));
    }
  }

  const enableUsers = async (action: any, id = -1) => {
    let ids = selectedRows;

    if (id > 0) ids = [id];
    const response: any = await enable({
      ids: ids,
      action: action === 1 ? 0 : 1,
    });
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    }
  };
  const deleteUsers = async (e: any, id = -1) => {
    console.log(e, id);
  };
  function handleSearch(e: any) {
    const params = new URLSearchParams(window.location.search);
    params.set("keyword", e.target.value);
    if (isNotNullOrUndefined(e.target.value)) {
      navigate(`${window.location.pathname}?${params.toString()}`);
    } else {
      navigate(`${window.location.pathname}`);
    }
  }

  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);
  //#endregion

  if (isFetching && !data) {
    return <Loading />;
  }

  return (
    <>
      <div id="properties">
        <div className="flex justify-between items-center mb-4 rounded-sm">
          <div className="flex overflow-x-auto">
            {selectedRows.length > 0 && (
              <div className="left-14 top-0 flex items-center space-x-3 sm:left-12 mr-3">
                <button
                  onClick={() => enableUsers(1)}
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-gray-500 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
                >
                  Disable
                </button>
                <button
                  onClick={() => enableUsers(0)}
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-green-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
                >
                  Enable
                </button>
                <button
                  onClick={deleteUsers}
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-red-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          <div className="sm:mt-1 text-right mt-0 ml-2 flex">
            <EFInput
              onChange={handleSearch}
              name="keyword"
              placeholder="Search"
            />
            <button
              onClick={() => navigate(urlCreate + "create")}
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-neutral-800 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm w-max ml-2"
            >
              <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Shto user
            </button>
          </div>
        </div>
        <div className="overflow-auto" style={{ height: height }}>
          <table className="min-w-full divide-y divide-neutral-300">
            <thead className="bg-neutral-300 dark:bg-neutral-800 dark:text-white sticky top-0 z-10">
              <tr>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                >
                  <input
                    type="checkbox"
                    className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 focus:ring-zinc-800"
                    ref={refCheckbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-300  text-right py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                >
                  Veprime
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-300 dark:divide-neutral-800 bg-white dark:bg-neutral-800">
              {data.data.map((user: any) => (
                <tr
                  key={user.id}
                  className={`${
                    selectedRows.includes(user.id)
                      ? "bg-neutral-300 dark:bg-neutral-900"
                      : "even:bg-neutral-100 dark:even:bg-neutral-900"
                  }`}
                >
                  <td className="relative px-7 sm:w-12 sm:px-6">
                    {selectedRows.includes(user.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-neutral-800 dark:bg-red-700 rounded-sm" />
                    )}
                    <input
                      type="checkbox"
                      className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-zinc-800 focus:ring-zinc-800"
                      value={user.id}
                      checked={selectedRows.includes(user.id)}
                      onChange={(e) => handleSelect(e, user)}
                    />
                  </td>

                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                    <div>{user.name}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                    <div>{user.email}</div>
                  </td>
                  <td className="whitespace-nowrap flex px-3 py-2 text-sm text-gray-500 float-right">
                    <button
                      onClick={(e) => enableUsers(user.enabled, user.id)}
                      className={`inline-flex items-center rounded ${
                        user.enabled === 1 ? "bg-green-700" : "bg-zinc-700"
                      } px-2 py-1 text-sm font-semibold text-white ml-1 ring-0`}
                    >
                      {user.enabled === 1 && (
                        <CheckBadgeIcon className="w-5 h-5 p-0.5" />
                      )}
                      Enabled
                    </button>
                    <button
                      onClick={(e) => deleteUsers(e, user.id)}
                      className="inline-flex items-center rounded bg-red-700 px-2 py-1 text-sm font-semibold text-white ml-1 ring-0"
                    >
                      <TrashIcon className="w-5 h-5 p-0.5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={data?.current_page ?? 1}
        from={data?.from ?? 0}
        to={data?.to ?? 0}
        total={data?.total ?? 0}
        last={data?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  );
}

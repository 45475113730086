import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isNotNullOrUndefined } from "../../services/helpers";

const DateTimePicker = (props: any) => {
  let defaultValue =
    props.defaultValue instanceof Date
      ? props.defaultValue
      : props.defaultValue
      ? new Date(props.defaultValue)
      : null;
  const dynamicKey = props.name;

  const handleChange = (e: any) => {
    props.onChange(e.toISOString());
  };

  return (
    <div className="w-full">
      {isNotNullOrUndefined(props.label) && (
        <label
          htmlFor={props.name}
          className={`block text-sm font-medium leading-6 text-gray-900 dark:text-white ${props.className}`}
        >
          {props.label}{" "}
          <span className="text-red-600">{props.required ? "*" : ""}</span>
        </label>
      )}
      <DatePicker
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        minDate={props.minDate}
        selected={defaultValue}
        onChange={handleChange}
        showTimeSelect={props.showTimeSelect ?? false}
        dateFormat="yyyy-MM-dd h:mm:ss"
        timeIntervals={15}
        className={`w-full z-20 border rounded-s border-gray-300 h-9 px-3 py-2 text-sm dark:text-white shadow-sm placeholder-slate-400 dark:placeholder-white focus:outline-none focus:border-gray-500 invalid:border-red-500 invalid:text-red-600`}
      />
      {props.validations && dynamicKey && props.validations[dynamicKey] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.validations[dynamicKey]}
        </p>
      )}
    </div>
  );
};

export default DateTimePicker;
